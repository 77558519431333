import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import clsx from "clsx";
import { useScript } from "usehooks-ts";

import type { PaymentMethod } from "../models/payment";
import type {
  SwedbankPayCheckoutConfiguration,
  SwedbankPayCheckoutInstance,
  SwedbankPayEligbleCheckInstrument,
} from "../models/swedbankPayCheckout";

import { useToaster } from "../hooks/common/useToaster";

import { useAppLocale } from "../recoil/i18nConfigState";
import { createUrlSearchParams } from "../utils/createUrlSearchParams";
import { Dialog } from "./Dialog";

declare global {
  interface Window {
    payex: {
      hostedView: {
        checkout: (
          params: SwedbankPayCheckoutConfiguration,
        ) => SwedbankPayCheckoutInstance;
      };
      getAcceptedWallets?: () => Promise<SwedbankPayEligbleCheckInstrument[]>;
    };
  }
}

const SWEDBANK_PAY_CONTAINER_ID = "swedbank-pay-container";

interface Props {
  scriptUrl: string;
  paymentMethod?: PaymentMethod["instrument"];

  /**
   * This object needs to be a stable reference, i.e. use useState, useMemo or useRef
   */
  configuration: Omit<
    SwedbankPayCheckoutConfiguration,
    "container" | "onOutOfViewRedirect"
  >;

  /**
   * This function needs to be a stable reference, i.e. use useCallback
   */
  onScriptLoad?: () => void;

  /**
   * This function needs to be a stable reference, i.e. use useCallback
   */
  onOutOfViewRedirectModalOpen?: (redirectUrl: string) => void;
}

export const SWEDBANK_PAY_OUT_OF_VIEW_POST_MESSAGE =
  "3DS-authentication-complete";

export const SwedbankPayCheckout = ({
  scriptUrl,
  paymentMethod,
  configuration,
  onScriptLoad,
  onOutOfViewRedirectModalOpen,
  children,
}: React.PropsWithChildren<Props>) => {
  const location = useLocation();
  const toaster = useToaster();
  const locale = useAppLocale();

  const checkoutInstanceRef = useRef<SwedbankPayCheckoutInstance>();

  const scriptLoadingStatus = useScript(scriptUrl, {
    removeOnUnmount: true,
  });

  const [outOfViewRedirectUrl, setOutOfViewRedirectUrl] = useState("");

  const loadIframe = useCallback(() => {
    if (checkoutInstanceRef.current) {
      checkoutInstanceRef.current.close();
    }

    checkoutInstanceRef.current = window.payex.hostedView.checkout({
      ...configuration,
      culture: locale,
      container: SWEDBANK_PAY_CONTAINER_ID,
      onOutOfViewRedirect: ({ redirectUrl }) => {
        if (redirectUrl.startsWith("swish://")) {
          const redirectUrlObject = new URL(redirectUrl);
          const searchParams = redirectUrlObject.searchParams;

          if (searchParams.has("callbackurl")) {
            const currentUrlObject = new URL(window.location.href);

            currentUrlObject.hash =
              currentUrlObject.hash +
              "?" +
              createUrlSearchParams({
                action: "swishRedirectCallback",
              }).toString();

            searchParams.set("callbackurl", currentUrlObject.toString());

            redirectUrlObject.search = searchParams.toString();
          }

          window.location.href = redirectUrlObject.toString();
          return;
        }

        if (!redirectUrl.startsWith("http")) {
          toaster.toastError.unknown();
          return;
        }

        setOutOfViewRedirectUrl(redirectUrl);

        onOutOfViewRedirectModalOpen?.(redirectUrl);
      },
    });

    checkoutInstanceRef.current.open();
  }, [configuration, locale, onOutOfViewRedirectModalOpen, toaster.toastError]);

  useEffect(() => {
    if (scriptLoadingStatus === "ready") {
      if (checkoutInstanceRef.current) {
        checkoutInstanceRef.current.close();
      }

      loadIframe();
      onScriptLoad?.();
    }

    return () => {
      checkoutInstanceRef.current?.close();
    };
  }, [scriptLoadingStatus, loadIframe, onScriptLoad]);

  useEffect(() => {
    if (
      scriptLoadingStatus === "ready" &&
      paymentMethod === "Swish" &&
      location.search.includes("action=swishRedirectCallback")
    ) {
      loadIframe();
    }
  }, [loadIframe, location.search, paymentMethod, scriptLoadingStatus]);

  const onMessage = useCallback(
    (message: MessageEvent<string>) => {
      if (message.data !== SWEDBANK_PAY_OUT_OF_VIEW_POST_MESSAGE) {
        return;
      }

      setOutOfViewRedirectUrl("");
      loadIframe();
    },
    [loadIframe],
  );

  useEffect(() => {
    window.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [onMessage]);

  return (
    <>
      <div id={SWEDBANK_PAY_CONTAINER_ID} className="min-h-[20rem]">
        {children}
      </div>

      {outOfViewRedirectUrl && (
        <Dialog
          visible
          onHide={() => null}
          closable={false}
          showHeader={false}
          className="max-w-none"
          contentClassName="!p-0"
        >
          <iframe
            src={outOfViewRedirectUrl}
            // Width and height values comes from
            // https://docs.stripe.com/payments/3d-secure/authentication-flow#render-iframe
            className={clsx(
              paymentMethod === "MobilePay"
                ? "h-[600px] w-[300px] md:h-[900px] md:w-[700px]"
                : "h-[600px] w-[300px] sm:w-[390px]",
            )}
          />
        </Dialog>
      )}
    </>
  );
};
