import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { faImage } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useSWR from "swr";
import { useDebounceValue } from "usehooks-ts";

import { adminGetSuperAdminFacilityPath } from "../../../../helpers/pathHelpers";

import { getAllFacilitiesForSuperadmin } from "../../../../modules/customer/services/FacilityService";

import { Button } from "../../../../components/Button";
import { Dialog } from "../../../../components/Dialog";
import { ProgressSpinner } from "../../../../components/ProgressSpinner";
import { TextInput } from "../../../../components/TextInput";

import { NewFacilityForm } from "./NewFacilityForm";

export const FacilitiesOverview = () => {
  const {
    data: facilities,
    isLoading,
    mutate,
  } = useSWR("superadmin/facilities", () =>
    getAllFacilitiesForSuperadmin(0, 9999).then(response => response.data),
  );

  const [displayCount, setDisplayCount] = useState(18);
  const [showNewFacilityDialog, setShowNewFacilityDialog] = useState(false);

  const [searchTerm, setSearchTerm] = useDebounceValue("", 500);

  const filteredFacilities = useMemo(() => {
    return facilities
      ?.filter(facility =>
        facility.name.toLowerCase().includes(searchTerm.toLowerCase()),
      )
      .sort((a, b) => a.name.localeCompare(b.name))
      .slice(0, displayCount);
  }, [displayCount, facilities, searchTerm]);

  return (
    <>
      <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:gap-8">
        <TextInput
          className="flex-auto"
          placeholder="Sök hall"
          defaultValue=""
          onChange={e => setSearchTerm(e.target.value.trim())}
        />
        <Button
          type="primary"
          translationName="facilities.add-new-venue"
          onClick={() => setShowNewFacilityDialog(true)}
        />
      </div>

      <div className="mt-8 sm:mt-16">
        <h3>
          <FormattedMessage id="common.all-venues" />
        </h3>
        <div className="flex items-center justify-between">
          <span>
            <FormattedMessage
              id="common.results.shows-x-of-x"
              values={{
                shows: isLoading ? "x" : filteredFacilities?.length,
                total: isLoading ? "x" : facilities?.length,
              }}
            />
          </span>
        </div>

        {isLoading ? (
          <ProgressSpinner className="mt-6" />
        ) : (
          <>
            <div className="mt-6 grid grid-cols-2 gap-4 sm:gap-8 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
              {filteredFacilities?.map(facility => (
                <Link
                  key={facility.id}
                  to={adminGetSuperAdminFacilityPath(facility.id)}
                  className="font-inherit text-inherit"
                >
                  <div className="flex aspect-square items-center justify-center rounded-lg border border-gray-50 p-4">
                    {facility.logo ? (
                      <img
                        src={facility.logo}
                        alt={facility.name}
                        className="max-h-full max-w-full"
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="icon text-8xl text-gray-500"
                        icon={faImage}
                      />
                    )}
                  </div>

                  <div className="mt-2">
                    <p className="font-bold">{facility.name}</p>
                    {facility.address?.street && (
                      <address className="mt-2 text-sm not-italic text-gray-700">
                        {facility.address.street},
                        <br />
                        {facility.address.postalCode} {facility.address.city}
                      </address>
                    )}
                  </div>
                </Link>
              ))}
            </div>

            {facilities && facilities.length > displayCount && (
              <Button
                className="mx-auto mt-20"
                type="primary"
                translationName="common.show-more"
                onClick={() => setDisplayCount(displayCount + 18)}
              />
            )}
          </>
        )}
      </div>

      {showNewFacilityDialog && (
        <Dialog visible onHide={() => setShowNewFacilityDialog(false)}>
          <NewFacilityForm
            onSuccess={() => {
              mutate(undefined);
              setShowNewFacilityDialog(false);
            }}
          />
        </Dialog>
      )}
    </>
  );
};
