import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import {
  faArrowsRotate,
  faBan,
  faIdBadge,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BookingType, type IBooking } from "../../../../models/Booking";

import {
  adminGetActivitesEventPath,
  adminGetActivitesSeriesPath,
} from "../../../../../../helpers/pathHelpers";

import { useGeneralActivity } from "../../../../../../hooks/swr/useGeneralActivity";
import { useSeries } from "../../../../../../hooks/swr/useSeries";

interface Props {
  booking: IBooking;
}

export const AdminCalendarDetailsHeader = ({ booking }: Props) => {
  if (
    [
      BookingType.Regular,
      BookingType.NotBookable,
      BookingType.Recurring,
      BookingType.Open,
    ].includes(booking.type)
  ) {
    return <Booking booking={booking} />;
  }

  if (booking.type === BookingType.Series) {
    return <Series booking={booking} />;
  }

  if (booking.type === BookingType.Event) {
    return <Event booking={booking} />;
  }
};

const Booking = ({ booking }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <h3>
        {booking.type === BookingType.NotBookable ? (
          <FormattedMessage id="calendar.slot.not-bookable" />
        ) : (
          booking.organizer?.displayName || (
            <FormattedMessage id="common.booking-details" />
          )
        )}
      </h3>

      {(booking.isAdmin || booking.type === BookingType.Recurring) && (
        <div className="mt-1 flex gap-2">
          {booking.type === BookingType.NotBookable ? (
            <div className="rounded-full bg-blue-50 px-3 py-0.5">
              <FontAwesomeIcon
                className="mr-1 inline-block"
                icon={faBan}
                title={formatMessage({ id: "calendar.slot.not-bookable" })}
              />
              <FormattedMessage id="calendar.slot.not-bookable" />
            </div>
          ) : booking.isAdmin ? (
            <div className="rounded-full bg-blue-50 px-3 py-0.5">
              <FontAwesomeIcon
                className="mr-1 inline-block"
                icon={faIdBadge}
                title={formatMessage({ id: "common.booked-by-admin" })}
              />
              Admin
            </div>
          ) : null}

          {booking.type === BookingType.Recurring && (
            <div className="flex items-center gap-1 rounded-full bg-blue-50 px-3 py-0.5">
              <FontAwesomeIcon
                className="mr-1 inline-block"
                icon={faArrowsRotate}
                title={formatMessage({ id: "common.recurring" })}
              />
              <FormattedMessage id="common.recurring" />
            </div>
          )}
        </div>
      )}
    </>
  );
};

const Series = ({ booking }: Props) => {
  const { series } = useSeries(booking.externalServiceId, "participants");

  if (!series) {
    return null;
  }

  <h3>
    <Link to={adminGetActivitesSeriesPath(series.id)} target="_blank">
      {series.name}
    </Link>
  </h3>;
};

const Event = ({ booking }: Props) => {
  const { activity: event } = useGeneralActivity(booking.externalServiceId, "");

  if (!event) {
    return null;
  }

  return (
    <h3>
      <Link to={adminGetActivitesEventPath(event.id)} target="_blank">
        {event.name}
      </Link>
    </h3>
  );
};
