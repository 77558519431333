import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Formik } from "formik";
import { DateTime } from "luxon";

import {
  type OpenBooking,
  OpenBookingClassCategoryEnum,
} from "../../../../../../../models/OpenBookings";
import { type IBooking } from "../../../../../models/Booking";

import { useToaster } from "../../../../../../../hooks/common/useToaster";
import { useOpenBooking } from "../../../../../../../hooks/swr/facilityOpenBookings/useOpenBooking";

import { updateOpenBooking } from "../../../../../../../services/facilityOpenBookingsService";

import { Button } from "../../../../../../../components/Button";
import { CalendarInput } from "../../../../../../../components/CalendarInput";
import { Label } from "../../../../../../../components/Label";
import { SkillLevelSlider } from "../../../../../../../components/SkillLevelSlider";
import { SelectInput } from "../../../../../../../components/inputs/SelectInput";

export const OpenBookingEdit = ({
  booking,
  openBooking,
  mutateOpenBooking,
  exitEditMode,
}: {
  booking: IBooking;
  openBooking: OpenBooking;
  mutateOpenBooking: ReturnType<typeof useOpenBooking>["mutate"];
  exitEditMode: () => void;
}) => {
  const { formatMessage } = useIntl();
  const toaster = useToaster();

  const [skillLevel, setSkillLevel] = useState<[number, number]>([
    openBooking.minSkillLevel,
    openBooking.maxSkillLevel,
  ]);

  if (!openBooking) {
    return null;
  }

  const facilityOffsetToAdd =
    DateTime.local({ zone: booking.facility.localization?.timeZone }).offset -
    DateTime.now().offset;

  const { minSkillLevel, maxSkillLevel, classCategory } = openBooking;

  return (
    <Formik
      initialValues={{
        minSkillLevel,
        maxSkillLevel,
        classCategory,
        automaticCancellationTime: openBooking.automaticCancellationTime.plus({
          minutes: facilityOffsetToAdd,
        }),
      }}
      onSubmit={async data => {
        try {
          const updatedOpenBooking = await updateOpenBooking(
            booking.facilityId,
            openBooking.id,
            {
              ...data,
              automaticCancellationTime: data.automaticCancellationTime.minus({
                minutes: facilityOffsetToAdd,
              }),
            },
          );
          mutateOpenBooking(updatedOpenBooking, false);
          toaster.toastSuccess.changesSaved();
          exitEditMode();
        } catch {
          toaster.toastError.unknown();
        }
      }}
    >
      {props => (
        <form className="mt-8" onSubmit={props.handleSubmit}>
          <div className="mb-4">
            <CalendarInput
              showTime
              stepMinute={30}
              name="automaticCancellationTime"
              label={formatMessage({
                id: "common.automatic-cancellation-time",
              })}
              value={props.values.automaticCancellationTime}
              onChange={e =>
                e.value &&
                props.setFieldValue("automaticCancellationTime", e.value)
              }
              minDate={DateTime.utc()
                .plus({
                  hours: 2,
                  minutes: facilityOffsetToAdd,
                })
                .startOf("hour")}
              maxDate={openBooking.startTime
                .plus({ minutes: facilityOffsetToAdd })
                .minus({ hours: 1 })}
            />
          </div>

          <div className="mb-4">
            <SelectInput
              label={formatMessage({
                id: "common.class",
              })}
              name="classCategory"
              value={props.values.classCategory}
              options={[
                {
                  value: OpenBookingClassCategoryEnum.Men,
                  label: formatMessage({
                    id: "gender-types.male",
                  }),
                },
                {
                  value: OpenBookingClassCategoryEnum.Women,
                  label: formatMessage({
                    id: "gender-types.female",
                  }),
                },
                {
                  value: OpenBookingClassCategoryEnum.Mixed,
                  label: formatMessage({
                    id: "gender-types.mix",
                  }),
                },
              ]}
              onChange={props.handleChange}
            />
          </div>

          <div className="mb-4 flex flex-1 flex-col gap-1.5">
            <Label>
              <FormattedMessage id="common.level" />
            </Label>
            <div className="flex min-w-96 flex-1 items-center pt-[22px] sm:mt-0">
              <span className="mr-8 w-[1.5em] flex-none text-right font-bold">
                {skillLevel[0].toFixed(1)}
              </span>
              <SkillLevelSlider
                min={1}
                max={10}
                step={0.1}
                value={skillLevel}
                onChange={(e, value) => {
                  if (!Array.isArray(value)) {
                    return;
                  }

                  setSkillLevel(value as [number, number]);
                }}
                onChangeCommitted={(_, value) => {
                  if (!Array.isArray(value)) {
                    return;
                  }

                  props.setFieldValue("minSkillLevel", value[0]);
                  props.setFieldValue("maxSkillLevel", value[1]);
                }}
              />
              <span className="ml-8 w-[1.5em] flex-none font-bold">
                {skillLevel[1].toFixed(1)}
              </span>
            </div>
          </div>

          <Button
            type="primary"
            buttonType="submit"
            translationName="button.save"
            disabled={!props.isValid || !props.dirty || props.isSubmitting}
            loading={props.isSubmitting}
          />
        </form>
      )}
    </Formik>
  );
};
