import { useRecoilValue } from "recoil";
import type { SWRConfiguration } from "swr";
import useSWR from "swr/immutable";

import { User } from "../../modules/player/models/User";

import {
  adminGetUser,
  getUser,
} from "../../modules/player/services/UserService";

import { userPermissionsState } from "../../recoil/Customer/userPermissionsState";

export const useUser = (
  userId: User["id"] | undefined | null,
  options?: SWRConfiguration,
) => {
  const userPermissions = useRecoilValue(userPermissionsState);
  const isAdmin = userPermissions?.length > 0;

  const fetcher = isAdmin ? adminGetUser : getUser;

  const { data, ...rest } = useSWR(
    userId ? ["user", userId] : null,
    ([, userId]) => fetcher(userId),
    { ...options, refreshInterval: MINUTE_IN_MILLISECONDS * 10 },
  );

  return {
    ...rest,
    user: data,
  };
};

const MINUTE_IN_MILLISECONDS = 1000 * 60;
