import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { DateTime } from "luxon";
import { InputSwitch } from "primereact/inputswitch";
import { useRecoilState } from "recoil";
import styled from "styled-components";

import { breakpoints } from "../../../../appConstants/common";

import { DateOnly } from "../../../../models/DateOnly";
import { BookingType } from "../../../../modules/checkout//models/Booking";
import { Series } from "../../../../modules/game/models/Series";
import { FacilityWithUtc } from "../../../customer/models/Facility";
import { IBooking } from "../../models/Booking";
import { ICalendarPayload } from "../../models/Calendar";

import { useIsMobile } from "../../../../hooks/common/useIsMobile";
import { useIsAdmin } from "../../../../hooks/useIsAdmin";

import { getSeries } from "../../../../modules/game/services/Serie";
import { getBooking } from "../../../checkout/services/Booking";
import { getFacility } from "../../../customer/services/FacilityService";
import { rescheduleBooking } from "../../services/Booking";

import { Button } from "../../../../components/Button";
import { ConfirmationDialog } from "../../../../components/ConfirmationDialog";
import { Dialog } from "../../../../components/Dialog";
import { LogoWithFallback } from "../../../../components/LogoWithFallback";
import { NothingToShow } from "../../../../components/NothingToShow";
import { ProgressSpinner } from "../../../../components/ProgressSpinner";
import { RescheduleBox } from "./components/RescheduleBox";

import {
  bookingDataState,
  facilityDataState,
  isRescheduleState,
} from "../../../../recoil/Customer/rescheduleState";
import { CalendarRulesReminder } from "./CalendarRulesReminder";
import CustomerCalendar from "./customer/CustomerCalendar";

const CustomerOverviewCalendarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: ${breakpoints.XL}) {
    gap: 2rem;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    display: flex;
  }
`;

const HeaderImage = styled.div`
  display: block;
  width: 5rem;
  height: 5rem;
  overflow: hidden;
  border-radius: var(--border-radius);

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

const HeaderContent = styled.div`
  margin: 0 1.2rem;
  font-size: 1.3rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
const HeaderButton = styled.div`
  height: auto;
  display: flex;
  align-items: flex-end;
`;

const H4bold = styled.span`
  font-weight: var(--bold);
`;

const FakeCalendarBody = styled.div`
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--gray-200);
  padding: 1rem;
`;

const SuccessTitle = styled.h4`
  margin-bottom: 0.5rem;
`;

interface Props {
  bookingId: string;
  facilityId: string;
  noHeader?: boolean;
}

export const UserRescheduleCalendar: React.FC<Props> = ({
  bookingId,
  facilityId,
  noHeader,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const isAdmin = useIsAdmin();
  const [booking, setBooking] = useRecoilState<IBooking>(bookingDataState);
  const [facility, setFacility] =
    useRecoilState<FacilityWithUtc>(facilityDataState);
  const [serie, setSerie] = useState<Series>(null);
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [calendarPayload, setCalendarPayload] =
    useState<ICalendarPayload>(null);
  const isMobile = useIsMobile();
  const facilityAbortController = useRef<AbortController>();
  const serieAbortController = useRef<AbortController>();
  const bookingAbortController = useRef<AbortController>();
  const [, setIsReschedule] = useRecoilState<boolean>(isRescheduleState);
  const [sendEmail, setSendEmail] = useState(true);
  useEffect(() => {
    setIsReschedule(true);
  }, []);

  const nrOfSlots =
    booking?.endTime.diff(booking?.startTime, "minutes").minutes / 30;

  const newCourtName = facility?.bookableEntities?.find(
    c => c?.id === calendarPayload?.courtId,
  )?.name;
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    facilityAbortController.current = new AbortController();
    bookingAbortController.current = new AbortController();
    const fetchBooking = async () => {
      try {
        const booking = await getBooking(
          bookingId,
          "facility",
          bookingAbortController.current?.signal,
        );
        setBooking(booking?.data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    const fetchFacility = async () => {
      try {
        const facility = await getFacility(
          facilityId,
          facilityAbortController.current?.signal,
        );
        if (facility?.data) setFacility(facility?.data);
      } catch (e) {
        if (!facilityAbortController.current?.signal.aborted) setFacility(null);
      } finally {
        setLoading(false);
      }
    };

    if (facilityId && bookingId) {
      fetchFacility();
      fetchBooking();
    }

    return () => {
      bookingAbortController.current?.abort();
      facilityAbortController.current?.abort();
    };
  }, [facilityId, bookingId]);

  useEffect(() => {
    serieAbortController.current = new AbortController();
    const fetchSerie = async () => {
      try {
        const serie = await getSeries(
          booking?.externalServiceId,
          null,
          serieAbortController.current?.signal,
        );
        if (serie?.data) setSerie(serie?.data);
      } catch (e) {
        if (!serieAbortController.current?.signal.aborted) setFacility(null);
      } finally {
        setLoading(false);
      }
    };

    if (booking?.type === BookingType.Series) {
      fetchSerie();
    }
  }, [booking]);

  const handleCalendarPayload = (data: ICalendarPayload) => {
    if (data && facility) {
      setCalendarPayload(data);
    } else {
      setCalendarPayload(null);
    }
  };

  const handleSubmit = async () => {
    try {
      await rescheduleBooking(
        bookingId,
        {
          facilityId: calendarPayload?.facilityId,
          courtId: calendarPayload?.courtId,
          endTime: calendarPayload?.endTime,
          startTime: calendarPayload?.startTime,
        },
        sendEmail,
      );

      setBooking({
        ...booking,
        court: facility.bookableEntities.find(
          entity => entity.id === calendarPayload.courtId,
        ),
        endTime: calendarPayload?.endTime,
        startTime: calendarPayload?.startTime,
      });
      setReload(!reload);
      setShowSuccess(true);
    } catch (e) {
      console.log(e);
    } finally {
      setShowConfirmation(false);
    }
  };

  const division = serie?.divisions?.find(
    d => d.id === booking?.externalReferenceId,
  );

  if (loading) {
    return (
      <CustomerOverviewCalendarWrapper>
        <ProgressSpinner />
      </CustomerOverviewCalendarWrapper>
    );
  }

  if (!facility) {
    return (
      <CustomerOverviewCalendarWrapper>
        <FakeCalendarBody>
          <NothingToShow
            translationId="user-overview.no-bookings"
            defaultMessage="Vi kunde inte hitta att du gjort några bokningar ännu.{br}Gör din första bokning för att visa din mest spelade hall här."
            values={{ br: <br /> }}
          />
        </FakeCalendarBody>
      </CustomerOverviewCalendarWrapper>
    );
  }

  if (
    !isAdmin &&
    booking?.type === BookingType.Series &&
    booking?.reschedulesCount >=
      division?.rescheduleRules?.maxAllowedBookingReschedules
  ) {
    return (
      <CustomerOverviewCalendarWrapper>
        <FakeCalendarBody>
          <NothingToShow translationId="series.reschedule.max-reschedules-reached" />
        </FakeCalendarBody>
      </CustomerOverviewCalendarWrapper>
    );
  }

  return (
    <CustomerOverviewCalendarWrapper>
      <>
        {!noHeader && (
          <Header>
            <div>
              <>
                <HeaderImage>
                  <LogoWithFallback src={facility?.logo} />
                </HeaderImage>
                <HeaderContent>
                  <H4bold>{facility?.name}</H4bold>

                  <small style={{ display: "block" }}>
                    {facility?.address?.city}, {facility?.address?.street}
                    {facility?.address?.streetNumber}
                  </small>
                </HeaderContent>
              </>
            </div>
            <div>
              <HeaderButton>
                {!isMobile && (
                  <Button
                    onClick={() => setShowConfirmation(true)}
                    style={{ fontSize: ".8rem", width: "8rem" }}
                    type="primary"
                    text="Boka"
                    translationName="button.book"
                    disabled={!calendarPayload}
                  />
                )}
              </HeaderButton>
            </div>
          </Header>
        )}

        {booking &&
          facility &&
          ((booking.type === BookingType.Series && division) ||
            booking.type !== BookingType.Series) && (
            <CustomerCalendar
              facility={facility}
              onChange={handleCalendarPayload}
              initialPayload={calendarPayload}
              bookingId={bookingId}
              nrOfSlots={nrOfSlots}
              initialDate={DateOnly.fromDateTime(
                DateTime.utc() > booking?.startTime
                  ? DateTime.utc()
                  : booking?.startTime,
              )}
              maxDate={
                division?.rescheduleRules?.limitToSelectedTime
                  ? division?.gamesCreationRules?.lastStartDate
                  : serie?.endTime
                    ? DateOnly.fromDateTime(serie?.endTime)
                    : null
              }
              minDate={
                division?.rescheduleRules?.limitToSelectedTime &&
                division?.gamesCreationRules?.firstStartDate >= DateOnly.today()
                  ? division?.gamesCreationRules?.firstStartDate
                  : serie?.startTime >= DateTime.now()
                    ? DateOnly.fromDateTime(serie?.startTime)
                    : undefined
              }
              reload={reload}
              bookingType={booking?.type}
              allowedWeekDays={
                division?.rescheduleRules?.limitToSelectedWeekDays
                  ? division?.gamesCreationRules?.weekDays
                  : undefined
              }
            />
          )}

        <CalendarRulesReminder />
      </>

      <Dialog visible={showSuccess} onHide={() => history.goBack()}>
        <SuccessTitle>
          <FormattedMessage
            id="games.reschedule.success.title"
            defaultMessage="Din ombokning är genomförd"
          />
        </SuccessTitle>
        <RescheduleBox
          facilityId={facilityId}
          startTime={booking?.startTime}
          endTime={booking?.endTime}
          courtName={booking?.court.name}
          isOldTime={false}
        />
      </Dialog>
      <ConfirmationDialog
        confirmText={intl.formatMessage({
          id: "games.reschedule",
          defaultMessage: "Omboka",
        })}
        visible={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        onCancel={() => setShowConfirmation(false)}
        onSubmit={handleSubmit}
      >
        <RescheduleBox
          facilityId={facilityId}
          startTime={booking?.startTime}
          endTime={booking?.endTime}
          courtName={booking?.court?.name}
          isOldTime
        />
        <RescheduleBox
          facilityId={facilityId}
          startTime={calendarPayload?.startTime}
          endTime={calendarPayload?.endTime}
          courtName={newCourtName}
          isOldTime={false}
        />
        {isAdmin && (
          <div className="my-4 flex justify-end gap-5">
            <label htmlFor="sendEmail">
              <FormattedMessage id="admin.send.participant.email" />
            </label>
            <InputSwitch
              inputId="sendEmail"
              checked={sendEmail}
              onChange={() => setSendEmail(!sendEmail)}
            />
          </div>
        )}
      </ConfirmationDialog>
    </CustomerOverviewCalendarWrapper>
  );
};
