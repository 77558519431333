interface Props {
  data: {
    heading: React.ReactNode;
    text?: React.ReactNode;
  }[];
}

export const DataGrid = ({ data }: Props) => {
  return (
    <div className="mt-8 grid grid-cols-2 gap-x-7 gap-y-4 break-words sm:w-4/5">
      {data.map(({ heading, text }, index) => (
        <div key={index}>
          <p className="font-medium text-gray-700">{heading}</p>
          {text && <div className="font-semibold">{text}</div>}
        </div>
      ))}
    </div>
  );
};
