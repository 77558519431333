import { useState } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import type { Tab } from "../../models/Tabs";

import { useIsSuperAdmin } from "../../hooks/permissions";

import { Tabs } from "../../components/Tabs";

import { SuperAdminFacilityPage } from "./facility/SuperAdminFacilityPage";
import { FacilitiesOverview } from "./tabs/FacilitiesOverview/FacilitiesOverview";
import { MaintenanceMessageTab } from "./tabs/MaintenanceMessageTab";

const tabs: Tab[] = [
  {
    id: 0,
    translationId: "navigation.menuitem.venues",
    Component: FacilitiesOverview,
  },
  {
    id: 1,
    translationId: "common.error-message",
    Component: MaintenanceMessageTab,
  },
];

export const SuperAdmin = () => {
  const { path } = useRouteMatch();

  const isSuperAdmin = useIsSuperAdmin();

  if (!isSuperAdmin) {
    return <Redirect to="/" push={false} />;
  }

  return (
    <Switch>
      <Route
        path={`${path}/facility/:facilityId`}
        component={SuperAdminFacilityPage}
      />
      <Route path={path} exact component={Home} />
    </Switch>
  );
};

const Home = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <Tabs
      activeIndex={activeTabIndex}
      onActiveIndexChange={index => setActiveTabIndex(index)}
      tabs={tabs}
    />
  );
};
