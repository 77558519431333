import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { faCoins, faTennisBall } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";

import {
  Membership,
  RenewalType,
} from "../../../../../../../modules/player/models/Membership";

import { useCurrencyFormat } from "../../../../../../../hooks/useCurrencyFormat";
import { useDateFormat } from "../../../../../../../hooks/useDateFormat";
import { useQuery } from "../../../../../../../hooks/useQuery";

import { payForMembership } from "../../../../../../../modules/player/services/MembershipService";

import { PaymentTerms } from "../../../../../../../components/PaymentTerms";
import { MembershipInfoBox } from "./components/MembershipInfoBox";

import { luxonDateFormat } from "../../../../../../../utils/dateFormats";

interface Props {
  membership: Membership;
  priceId: string;
  setVisible: any;
}

export const PaymentForm: React.FC<Props> = ({
  membership,
  priceId,
  setVisible,
}) => {
  const query = useQuery();
  const { df } = useDateFormat(membership.facilityId);
  const paymentCompleted = !!query?.get("paymentCompleted");
  const membershipPrice = membership?.membershipPrices?.find(
    p => p?.id === priceId,
  );
  const fromDate = df(DateTime.utc(), luxonDateFormat);
  const toDate = df(membershipPrice?.expiryDate, luxonDateFormat);
  const nextRenewalDate = df(membershipPrice?.nextRenewalDate, luxonDateFormat);
  const { cf } = useCurrencyFormat(membershipPrice.price.currencyCode);

  const [error, setError] = useState<boolean>(false);

  const priceText =
    membershipPrice?.renewal?.renewalType === RenewalType.Monthly ? (
      <>
        {cf(membershipPrice?.price.valueInclTax)} /{" "}
        <FormattedMessage id="common.month" />
      </>
    ) : (
      <>
        {cf(membershipPrice?.price.valueInclTax)} /{" "}
        <FormattedMessage id="common.year" />
      </>
    );

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (paymentCompleted) {
      setVisible?.(false);
    }
  }, [paymentCompleted]);

  const init = async () => {
    try {
      const payment = await payForMembership({
        membershipId: membership?.id,
        membershipPriceId: membershipPrice?.id,
        paymentCompleteUrl: `${window.location.origin}/#/venues/${membership?.facilityId}?tab=membership&paymentCompleted=true&purchased=${membership?.id}&paymentId={2}`,
        cardPaymentUrl: `${window.location.origin}/#/venues/${membership?.facilityId}?tab=membership&paymentPending=true&paymentId={2}`,
      });
      await renderIframe(payment?.paymentUrl);
    } catch {
      setError(true);
    }
  };

  const renderIframe = async (iframeUrl: string) => {
    const wrapperDiv = document.getElementById(
      "swedbank-pay-seamless-view-page",
    );

    // make sure wrapper is empty before rendering the iframe
    if (wrapperDiv.innerHTML !== "") wrapperDiv.innerHTML = "";

    const paymentScript = document.createElement("script");
    paymentScript.src = iframeUrl;
    paymentScript.onload = function () {
      (window as any)?.payex?.hostedView
        .creditCard({ container: "swedbank-pay-seamless-view-page" })
        .open();
    };
    wrapperDiv.appendChild(paymentScript);
  };

  return (
    <div>
      <h1 className="mb-8 text-xl text-pureblack">
        <FormattedMessage
          id="membership.purchase.title"
          defaultMessage="Köp medlemskap"
        />
      </h1>
      <div className="mb-8 flex flex-col gap-4 md:grid md:grid-cols-2">
        <MembershipInfoBox
          icon={<FontAwesomeIcon icon={faTennisBall} />}
          title={
            <FormattedMessage
              id="common.membership"
              defaultMessage="Medlemskap"
            />
          }
          text={membership?.name}
          info={
            <FormattedMessage
              id="membership.purchase.period"
              defaultMessage="{fromDate} - {toDate}"
              values={{ toDate, fromDate }}
            />
          }
        />
        <MembershipInfoBox
          icon={<FontAwesomeIcon icon={faCoins} />}
          title={<FormattedMessage id="common.price" defaultMessage="Pris" />}
          text={priceText}
          info={
            <FormattedMessage
              id="membership.purchase.nextPayment"
              defaultMessage="Nästa betalning {nextRenewalDate}"
              values={{ nextRenewalDate }}
            />
          }
        />
      </div>

      {membership.isSubscriptionMembership && (
        <div className="mb-8 flex flex-col">
          <h4>
            <FormattedMessage
              id="membership.automatic.renewal.title"
              defaultMessage="Automatisk förnyelse"
            />
          </h4>
          <p>
            <FormattedMessage
              id="membership.automatic.renewal.description"
              defaultMessage="Ditt medlemskap förnyas automatiskt en månad före {expiryDate}."
              values={{ expiryDate: toDate }}
            />
          </p>
        </div>
      )}

      <div id="swedbank-pay-seamless-view-page" />

      <PaymentTerms
        skipRescheduleInfo
        facilityId={membership.facilityId}
        className="mt-2 text-center"
      />

      {error && (
        <div className="mb-2 mt-2 text-center hover:cursor-pointer hover:text-gray-600">
          <FormattedMessage
            id="general.error.contact.support"
            defaultMessage="Något gick fel, vänligen kontakta kundtjänst"
          />
        </div>
      )}
    </div>
  );
};
