import { useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Tab } from "../../../models/Tabs";
import { VendorType } from "../../../modules/customer/models/Access";

import {
  useHasFacilityOwnerPermission,
  useIsFacilityAdmin,
  useIsFacilityOwner,
  useIsSuperAdmin,
} from "../../../hooks/permissions";
import { useIsFeatureFlagEnabled } from "../../../hooks/swr/mySettings/useFeatureFlags";
import { useSelectedFacility } from "../../../hooks/swr/useSelectedFacility";
import { useVendorType } from "../../../hooks/swr/useVendorType";

import { Tabs } from "../../../components/Tabs";

import { facilityTabs } from "../../../modules/player/constants";
import { AdminFacilitySettingsCoins } from "./coins/AdminFacilitySettingsCoins";
import { AdminFacilitySettingsStatistics } from "./coins/AdminFacilitySettingsStatistics";
import { FacilityBookableObjects } from "./courts";
import { FacilityPayment } from "./economy";
import { FacilityManagementSettings } from "./facility";
import { FacilityGeneralSettings } from "./general";
import { FacilityGroupsManagementSettings } from "./groups-memberships";
import { FacilityPriceManagementSettings } from "./pricing";
import { FacilityRoleSettings } from "./roles";
import { FacilityBusinessRulesSettings } from "./settings/FacilityBusinessRulesSettings";

interface Params {
  tab?: string;
}

export const AdminFacilitySettingsPage = () => {
  const { tab } = useParams<Params>();

  const history = useHistory();
  const activeTab = tab ? Number(tab) : facilityTabs.GENERAL;

  const { selectedFacility } = useSelectedFacility();

  const isFacilityOwner = useIsFacilityOwner();
  const isFacilityAdmin = useIsFacilityAdmin();
  const isSuperAdmin = useIsSuperAdmin();
  const { hasFacilityOwnerPermission } = useHasFacilityOwnerPermission();
  const statisticsDashboardEnabled =
    useIsFeatureFlagEnabled("Dashboard.Facility");

  const { vendorType } = useVendorType(selectedFacility?.id);

  const tabs = useMemo(() => {
    const tabs: Tab[] = [
      {
        id: facilityTabs.GENERAL,
        translationId: "common.general",
        Component: FacilityGeneralSettings,
      },
      {
        id: facilityTabs.COURTS,
        translationId: "common.courts",
        Component: FacilityBookableObjects,
      },
      {
        id: facilityTabs.GROUPS,
        translationId: "common.groups-and-memberships",
        Component: FacilityGroupsManagementSettings,
      },
      {
        id: facilityTabs.PRICES,
        translationId: "common.prices",
        Component: FacilityPriceManagementSettings,
      },
    ];

    if (hasFacilityOwnerPermission) {
      tabs.push({
        id: facilityTabs.ECONOMY,
        translationId: "common.economy",
        Component: FacilityPayment,
      });

      tabs.push({
        id: facilityTabs.ROLES,
        translationId: "admin.roles",

        Component: FacilityRoleSettings,
      });

      tabs.push({
        id: facilityTabs.SETTINGS,
        translationId: "common.settings",

        Component: FacilityBusinessRulesSettings,
      });
    }

    if (vendorType !== VendorType.None) {
      tabs.push({
        id: facilityTabs.FACILITY,
        translationId: "common.facility",
        Component: FacilityManagementSettings,
      });
    }

    if (hasFacilityOwnerPermission) {
      tabs.push({
        id: facilityTabs.COINS,
        translationId: "common.coins",
        Component: AdminFacilitySettingsCoins,
      });
    }

    if (statisticsDashboardEnabled && hasFacilityOwnerPermission) {
      tabs.push({
        id: facilityTabs.STATISTICS,
        translationId: "common.statistics",
        Component: AdminFacilitySettingsStatistics,
      });
    }

    return tabs;
  }, [hasFacilityOwnerPermission, statisticsDashboardEnabled, vendorType]);

  useEffect(() => {
    if (activeTab === facilityTabs.GENERAL) {
      return;
    }

    if (!isFacilityOwner && !isFacilityAdmin && !isSuperAdmin) {
      history.replace(`/admin/settings/${facilityTabs.GENERAL}`);
    }

    if (vendorType === VendorType.None && activeTab === facilityTabs.FACILITY) {
      history.replace(`/admin/settings/${facilityTabs.GENERAL}`);
    }
  }, [activeTab, history, isFacilityOwner, isSuperAdmin, vendorType]);

  if (!selectedFacility?.id) {
    return null;
  }

  return (
    <Tabs
      tabs={tabs}
      activeIndex={activeTab}
      onActiveIndexChange={index =>
        index !== activeTab && history.push(`/admin/settings/${index}`)
      }
    />
  );
};
