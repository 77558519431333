import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useIsAuthenticated } from "../hooks/useIsAuthenticated";

import { msalInstance } from "../index";
import { useAppI18nConfig } from "../recoil/i18nConfigState";

export const SignInRedirectPage = () => {
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();

  const {
    i18nConfig: { languageCode },
  } = useAppI18nConfig();

  useEffect(() => {
    if (!isAuthenticated) {
      msalInstance.loginRedirect({
        scopes: [],
        extraQueryParameters: { lang: languageCode },
      });
    } else {
      history.push({ pathname: "/" });
    }
  }, [history, isAuthenticated, languageCode]);

  return null;
};
