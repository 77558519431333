import useSWRImmutable from "swr/immutable";

import { FacilityWithUtc } from "../../modules/customer/models/Facility";

import { getVendorType } from "../../modules/customer/services/AccessService";

export const useVendorType = (
  facilityId: FacilityWithUtc["id"] | undefined | null,
) => {
  const { data, ...rest } = useSWRImmutable(
    facilityId ? ["vendorType", facilityId] : null,
    ([, facilityId]) => getVendorType(facilityId).then(res => res?.data),
  );

  return {
    ...rest,
    vendorType: data,
  };
};
