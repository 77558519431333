import { useMemo } from "react";

import useSWR from "swr";

import { DateOnly } from "../../../../models/DateOnly";
import { FacilityWithUtc } from "../../../customer/models/Facility";

import { useFacility } from "../../../../hooks/swr/useFacility";

import {
  getAdminOverviewSchedules,
  getAvailableRescheduleSlots,
  getSchedules,
} from "../../services/Schedule";

import { useAdminCalendarContextState } from "../../components/Calendar/admin/AdminCalendarContext";

import { useAdminCalendarVisibleCourtFilterState } from "../../../../recoil/adminCalendarVisibleCourtFilterState";

export const useSchedules = (
  selectedDate: DateOnly,
  facilityId?: FacilityWithUtc["id"],
  bookingId?: string,
  isAdmin?: boolean,
) => {
  const { data, ...rest } = useSWR(
    selectedDate && facilityId
      ? ["schedules", selectedDate.toISO(), facilityId, bookingId, isAdmin]
      : null,
    ([, selectedDate, facilityId, bookingId, isAdmin]) => {
      return isAdmin
        ? getAdminOverviewSchedules(
            DateOnly.fromISODate(selectedDate),
            facilityId,
          ).then(response => response.data)
        : bookingId
          ? getAvailableRescheduleSlots(
              bookingId,
              DateOnly.fromISODate(selectedDate),
            ).then(response => response.data)
          : getSchedules(DateOnly.fromISODate(selectedDate), facilityId).then(
              response => response.data,
            );
    },
    {
      fallbackData: [],
      revalidateOnFocus: false,
      // refresh every minute
      refreshInterval: 60_000,
      dedupingInterval: 10_000,
    },
  );

  return {
    schedules: data,
    ...rest,
  };
};

export const useAdminFilteredSchedules = () => {
  const { selectedDate, facilityId } = useAdminCalendarContextState();
  const { facility } = useFacility(facilityId, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const visibleCourts = useAdminCalendarVisibleCourtFilterState();

  const { schedules, ...rest } = useSchedules(
    selectedDate,
    facilityId,
    undefined,
    true,
  );

  const filteredSchedule = useMemo(() => {
    if (!schedules) {
      return schedules;
    }

    if (visibleCourts === "all") {
      return schedules;
    }

    if (!facility?.bookableEntities) {
      return schedules;
    }

    return schedules?.filter(court => {
      const bookableEntity = facility.bookableEntities?.find(
        entity => entity.id === court.objectId,
      );

      return visibleCourts === "open"
        ? !bookableEntity?.isHidden
        : bookableEntity?.isHidden;
    });
  }, [facility?.bookableEntities, schedules, visibleCourts]);

  return {
    schedules: filteredSchedule,
    ...rest,
  };
};
