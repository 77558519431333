import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { FacilityWithUtc } from "../../../modules/customer/models/Facility";

interface FacilitiesState {
  selectedFacility: FacilityWithUtc;
}

const initialState: FacilitiesState = {
  selectedFacility: {
    id: null,
    name: "",
    logo: "",
    description: "",
    openHours: [],
    bookableEntityTypes: [],
    bookableEntities: [],
    address: {
      country: "",
      city: "",
      street: "",
      streetNumber: "",
      postalCode: "",
    },
    email: "",
    phone: "",
    pincodeSuffix: "",
    website: "",
    paymentInformation: {
      invoiceEmail: "",
      plusgiro: "",
      bankgiro: "",
      registrationNumber: "",
      costCenter: "",
    },
    amenities: [],
    termsAndConditions: false,
    facilityBookingRules: {
      allowVacantSlotAroundOpenTime: true,
      allowVacantSlotAroundClosingTime: true,
      allowVacantSlotAroundCurrentTime: true,
      numberOfDaysBookingDaysAhead: 14,
      numberOfHoursBeforeBookingCancellation: 6,
      numberOfHoursBeforeSeriesGameReschedule: 6,
      numberOfHoursBeforeActivityCancellation: 6,
      playerCanCancelRecurring: true,
      numberOfHoursAfterBookingDuePaymentRuns: 2,
      onlyMemberships: [],
    },
    communicationSettings: {
      showShortMessageInBookingConfirmationEmail: false,
    },
  },
};

const facilitiesSlice = createSlice({
  name: "facilities",
  initialState,
  reducers: {
    setSelectedFacility: (
      state: FacilitiesState,
      action: PayloadAction<{ facility: FacilityWithUtc }>,
    ) => {
      state.selectedFacility = action.payload.facility;
    },
  },
});

export const { setSelectedFacility } = facilitiesSlice.actions;

export default facilitiesSlice.reducer;
