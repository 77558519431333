import { useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useFormik } from "formik";

import {
  FacilityCreatePaymentInfoRequest,
  PaymentInformation,
} from "../../../../modules/customer/models/Facility";

import { useToaster } from "../../../../hooks/common/useToaster";
import { useSelectedFacility } from "../../../../hooks/swr/useSelectedFacility";
import { useFormValidationSchema } from "../../../../modules/customer/hooks/useFormValidationSchema";
import { useRefetchFacilityInformation } from "../../../../modules/customer/hooks/useRefetchFacilityInformation";

import { updatePaymentInfo } from "../../../../modules/customer/services/FacilityService";

import { TextInput } from "../../../../components/TextInput";
import { FacilitySubmitResetButtons } from "../../../../modules/customer/components/FacilitySubmitResetButtons";
import { FacilitySettlementReports } from "./components/FacilitySettlementReports";
import { Unpaid } from "./components/Unpaid";

import { useSelectedFacilityId } from "../../../../recoil/selectedFacilityIdState";

export const FacilityPayment = () => {
  const intl = useIntl();
  const { toastSuccess, toastError } = useToaster();
  const { facilityPaymentInfoSchema } = useFormValidationSchema();
  const { facilityUpdatedCallback } = useRefetchFacilityInformation();
  const selectedFacilityId = useSelectedFacilityId();
  const { selectedFacility } = useSelectedFacility();
  const updatePaymentAbortController = useRef<AbortController>();

  const formik = useFormik<PaymentInformation>({
    enableReinitialize: true,
    initialValues: {
      invoiceEmail: selectedFacility?.paymentInformation?.invoiceEmail ?? "",
      registrationNumber:
        selectedFacility?.paymentInformation?.registrationNumber ?? "",
      plusgiro: selectedFacility?.paymentInformation?.plusgiro ?? "",
      bankgiro: selectedFacility?.paymentInformation?.bankgiro ?? "",
      costCenter: selectedFacility?.paymentInformation?.costCenter ?? "",
    },
    validationSchema: facilityPaymentInfoSchema,

    onSubmit: async (data: FacilityCreatePaymentInfoRequest) => {
      return facilityUpdatedCallback(async () => {
        updatePaymentAbortController.current = new AbortController();

        try {
          await updatePaymentInfo(
            selectedFacilityId,
            data,
            updatePaymentAbortController.current?.signal,
          );
          toastSuccess.infoSaved();
          return true;
        } catch (e) {
          if (!updatePaymentAbortController.current?.signal.aborted)
            e.hasValidationErrors
              ? toastError.validation()
              : toastError.saveFailed();
        }
      });
    },
  });

  return (
    <div>
      <h3>
        <FormattedMessage id="facility-settings.invoice-details" />
      </h3>

      <form
        className="mb-8 mt-4 flex max-w-prose flex-col gap-8"
        onSubmit={formik.handleSubmit}
        onReset={formik.handleReset}
      >
        <div>
          <TextInput
            required
            name="invoiceEmail"
            label={intl.formatMessage({
              id: "facility-settings.bookable.invoice-email",
            })}
            value={formik.values.invoiceEmail ?? ""}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.errors.invoiceEmail}
          />
        </div>

        <div>
          <TextInput
            required
            name="registrationNumber"
            label={intl.formatMessage({ id: "common.org-nr" })}
            value={formik.values.registrationNumber ?? ""}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.errors.registrationNumber}
          />
        </div>

        {/* TODO: These fields will be added later*/}
        {/*<SplitRow>*/}
        {/*  <div>*/}
        {/*    <TextInput*/}
        {/*      max={9}*/}
        {/*      name="plusgiro"*/}
        {/*      label={intl.formatMessage({ id: "common.pg" })}*/}
        {/*      defaultValue={formik.values.plusgiro ?? ""}*/}
        {/*      onBlur={formik.handleBlur}*/}
        {/*      onChange={formik.handleChange}*/}
        {/*      className={clsx({*/}
        {/*        "p-invalid": isFormFieldInvalid("plusgiro"),*/}
        {/*      })}*/}
        {/*    />*/}
        {/*    {getFormErrorMessage("plusgiro")}*/}
        {/*  </div>*/}
        {/*  <div>*/}
        {/*    <TextInput*/}
        {/*      max={9}*/}
        {/*      name="bankgiro"*/}
        {/*      label={intl.formatMessage({ id: "common.bg" })}*/}
        {/*      defaultValue={formik.values.bankgiro ?? ""}*/}
        {/*      onBlur={formik.handleBlur}*/}
        {/*      onChange={formik.handleChange}*/}
        {/*      className={clsx({*/}
        {/*        "p-invalid": isFormFieldInvalid("bankgiro"),*/}
        {/*      })}*/}
        {/*    />*/}
        {/*    {getFormErrorMessage("bankgiro")}*/}
        {/*  </div>*/}
        {/*</SplitRow>*/}

        <FacilitySubmitResetButtons disabled={!formik.dirty} />
      </form>

      <hr className="my-16" />

      <FacilitySettlementReports />

      <hr className="my-16" />

      <div className="mt-8">
        <Unpaid />
      </div>
    </div>
  );
};
