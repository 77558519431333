import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import { DateOnly } from "../../models/DateOnly";
import type { CardBrand } from "../../models/backupPaymentMethod";
import { CardInfo } from "../../modules/player/models/User";

import { useToaster } from "../../hooks/common/useToaster";
import { useQuery } from "../../hooks/useQuery";

import { sendCallback } from "../../modules/checkout/services/Payment";
import {
  getUserCardInfo,
  getUserCardInfoAsAdmin,
} from "../../modules/player/services/UserService";

import { CardVerify } from "./components/CardVerify";
import { RemoveCard } from "./components/RemoveCard";

import { AppLoadingSpinner } from "../AppLoadingSpinner";
import { CreditCardCard } from "../CreditCardCard";
import { Dialog } from "../Dialog";
import { PendingPayment } from "../PendingPayment";

interface Props {
  isReadOnly?: boolean;
  facilityId?: string;
  userId?: string;
}

export const CardDetails = ({
  isReadOnly = false,
  facilityId,
  userId,
}: Props) => {
  const query = useQuery();
  const history = useHistory();

  const toaster = useToaster();

  const [showVerifyCard, setShowVerifyCard] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [cardInfo, setCardInfo] = useState<CardInfo>();
  const operation =
    !cardInfo?.hasPaymentToken && !cardInfo?.hasRecurrenceToken
      ? "add"
      : cardInfo?.hasPaymentToken && !cardInfo?.hasRecurrenceToken
        ? "verify"
        : "change";
  const paymentId = query?.get("paymentId");
  const verifyCompleted = !!query?.get("verifyCompleted");
  const [showPendingDialog, setShowPendingDialog] = useState(
    !!query?.get("verifyPending"),
  );

  const getCardInfo = async () => {
    try {
      const info =
        facilityId && userId
          ? await getUserCardInfoAsAdmin(facilityId, userId)
          : await getUserCardInfo();

      setCardInfo(info);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      getCardInfo();
    } catch {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const abortController = new AbortController();

    const checkStatus = async () => {
      try {
        await sendCallback(query?.get("paymentId"), abortController.signal);
        if (abortController.signal.aborted) return;

        await getCardInfo();
        if (abortController.signal.aborted) return;

        query?.delete("verifyCompleted");
        query?.delete("paymentId");

        history.replace({ search: query?.toString() });

        setShowVerifyCard(false);
        setShowPendingDialog(false);

        toaster.toastSuccess.message("card-details.success-message");
      } catch (e) {
        if (abortController.signal.aborted) return;
        console.error(e);
        setLoading(false);
      }
    };

    if (verifyCompleted) {
      checkStatus();
    }

    return () => abortController.abort();
  }, [verifyCompleted]);

  if (loading) {
    return (
      <div className="flex grow justify-center">
        <AppLoadingSpinner hasContainer={false} />
      </div>
    );
  }

  return (
    <div className={`flex ${!isReadOnly && "flex-col"} grow`}>
      <div className="flex grow flex-row flex-wrap justify-between">
        {cardInfo?.maskedPan && (
          <CreditCardCard
            pan={cardInfo.maskedPan}
            expiryDate={DateOnly.fromDateTime(cardInfo.expiryDate)}
            removeable={false}
            cardBrand={cardInfo.cardBrand as keyof typeof CardBrand}
          />
        )}

        {!cardInfo?.cardBrand && (
          <p className="items-center text-pureblack">
            <FormattedMessage id="card-details.no-card" />
          </p>
        )}

        {!isReadOnly && (
          <div className="flex flex-col items-center justify-start text-sm text-gray-700">
            {cardInfo?.hasPaymentToken && !cardInfo?.hasRecurrenceToken && (
              <FormattedMessage id="card-details.verify-membership" />
            )}

            {(operation !== "add" || cardInfo?.isDeletable === false) && (
              <div>
                <button
                  type="button"
                  className="rounded-[4px] bg-primary px-8 py-2 font-bold text-purewhite"
                  onClick={() => setShowVerifyCard(true)}
                >
                  {operation === "verify" && (
                    <FormattedMessage id="card-details.verify-card" />
                  )}
                  {operation === "change" && (
                    <FormattedMessage id="card-details.change-card" />
                  )}

                  {operation === "add" && (
                    <FormattedMessage id="card-details.add-card" />
                  )}
                </button>
              </div>
            )}

            {cardInfo?.maskedPan && cardInfo?.isDeletable && (
              <div className="mt-1">
                <RemoveCard
                  onCardRemoved={() => {
                    getCardInfo();
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>

      <Dialog
        visible={showVerifyCard}
        onHide={() => setShowVerifyCard(false)}
        closeOnEscape
      >
        <CardVerify operation={operation} />
      </Dialog>

      {showPendingDialog && (
        <Dialog
          onHide={() => {
            setShowPendingDialog(false);
            history.replace({ search: "" });
          }}
          visible
        >
          <PendingPayment paymentId={paymentId} />
        </Dialog>
      )}
    </div>
  );
};
