import { FormattedMessage, useIntl } from "react-intl";

import { DateTime } from "luxon";

import { useFacility } from "../../../hooks/swr/useFacility";
import { useDateFormat } from "../../../hooks/useDateFormat";

import { luxonTimeFormat } from "../../../utils/dateFormats";
import { CheckoutViewHeading } from "../CheckoutView";
import { useCheckoutState } from "../context/CheckoutContext";
import { OrderDetails } from "./OrderDetails";

export const BookingOrderDetails = () => {
  const intl = useIntl();

  const state = useCheckoutState();

  const { facility } = useFacility(state.order.facilityId);
  const { df } = useDateFormat(state.order.facilityId);

  if (state.orderType !== "booking" && state.orderType !== "open-booking") {
    return null;
  }

  const { booking } = state;

  const orderDetails = [
    {
      heading: intl.formatMessage({
        id: "common.date-and-time",
      }),
      text: (
        <>
          {df(booking.startTime, DateTime.DATETIME_MED)}-
          {df(booking.endTime, luxonTimeFormat)}
        </>
      ),
    },
    {
      heading: intl.formatMessage({
        id: "common.venue",
      }),
      text: booking.facility.name,
    },
    {
      heading: intl.formatMessage({
        id: "common.court",
      }),
      text: (
        <>
          {booking.court.name}
          <br />
          {booking.court.bookableEntityType?.name}
        </>
      ),
    },
  ];

  if (facility?.shortMessage) {
    orderDetails.push({
      heading: intl.formatMessage({
        id: "common.facility.info",
      }),
      text: facility?.shortMessage ?? "",
    });
  }

  return (
    <div>
      <CheckoutViewHeading>
        <FormattedMessage id="checkout.section.order-details.heading" />
      </CheckoutViewHeading>

      <OrderDetails orderDetails={orderDetails} />
    </div>
  );
};
