import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { FormattedMessage } from "react-intl";

import {
  faArrowUpFromBracket,
  faCheck,
  faClose,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import type { FacilityWithUtc } from "../../../../modules/customer/models/Facility";

import { useToaster } from "../../../../hooks/common/useToaster";

import { importUsersToFacility } from "../../../../modules/player/services/UserService";

import { Button } from "../../../../components/Button";
import { ProgressSpinner } from "../../../../components/ProgressSpinner";
import { RadioButton } from "../../../../components/RadioButton";

export const UserImport = ({
  facilityId,
}: {
  facilityId: FacilityWithUtc["id"];
}) => {
  const toaster = useToaster();

  const { getRootProps, getInputProps, acceptedFiles, isDragAccept, inputRef } =
    useDropzone({
      maxFiles: 1,
      multiple: false,
      accept: {
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      },
      preventDropOnDocument: false,
    });

  const [selectedSystem, setSelectedSystem] = useState<string>("matchi");
  const [importState, setImportState] = useState<
    "idle" | "uploading" | "completed"
  >("idle");

  const handleImportUsers = async (data: File) => {
    setImportState("uploading");

    try {
      await importUsersToFacility(facilityId, data, selectedSystem);

      removeSelectedFile();
      setImportState("completed");
    } catch (e) {
      toaster.toastError.unknown();
      setImportState("idle");
    }
  };

  const removeSelectedFile = () => {
    if (!acceptedFiles.length) {
      return;
    }

    if (!inputRef.current) {
      return;
    }

    inputRef.current.value = "";
    inputRef.current?.dispatchEvent(new Event("change", { bubbles: true }));
  };

  return (
    <>
      <h3>
        <FormattedMessage id="common.import-users" />
      </h3>

      <div className="mt-8 flex justify-between">
        <div className="max-w-prose">
          <FormattedMessage id="super-admin.text.import-users-p-1" />{" "}
          <FormattedMessage id="super-admin.text.import-users-p-2" />{" "}
          <FormattedMessage id="super-admin.text.import-users-p-3" />
        </div>
      </div>

      <div className="mt-8">
        <>
          <div>
            {["matchi", "court22"].map((system: string) => (
              <RadioButton
                key={system}
                name={system}
                onChange={() => setSelectedSystem(system)}
                checked={selectedSystem === system}
                label={system}
              />
            ))}
          </div>

          <div
            {...(importState === "idle" && acceptedFiles.length < 1
              ? getRootProps()
              : undefined)}
            className={clsx(
              "mt-4 flex max-w-prose items-center justify-center rounded border border-dashed py-10",
              isDragAccept ? "border-primary" : "border-gray-50",
              importState === "idle" &&
                acceptedFiles.length < 1 &&
                "cursor-pointer",
            )}
          >
            <input {...getInputProps()} />

            <div className="grid w-2/3 place-content-center text-center">
              <div
                className={clsx(
                  "col-start-1 row-start-1",
                  (importState !== "idle" || acceptedFiles.length > 0) &&
                    "invisible",
                )}
              >
                <FontAwesomeIcon
                  icon={faArrowUpFromBracket}
                  size="3x"
                  className="inline-block text-blue-50"
                />
                <p className="mt-4 font-bold">
                  <FormattedMessage id="common.file-upload-drop.message" />
                </p>
              </div>

              <div
                className={clsx(
                  "col-start-1 row-start-1 flex flex-col items-center gap-6",
                  (acceptedFiles.length < 1 || importState === "uploading") &&
                    "invisible",
                )}
              >
                {acceptedFiles.map((file: File) => (
                  <div
                    key={file.name}
                    className="relative inline-block rounded border border-gray-50 bg-white px-6 py-3 font-bold"
                  >
                    <button
                      className="absolute right-0 top-0 z-10 flex size-5 -translate-y-1/2 translate-x-1/2 items-center justify-center rounded-full bg-red-600 text-purewhite"
                      onClick={e => {
                        e.stopPropagation();
                        removeSelectedFile();
                      }}
                    >
                      <FontAwesomeIcon icon={faClose} fixedWidth />
                      <span className="sr-only">
                        <FormattedMessage id="common.remove" />
                      </span>
                    </button>
                    {file.name}
                  </div>
                ))}

                <Button
                  type="primary"
                  size="small"
                  onClick={e => {
                    e.stopPropagation();
                    handleImportUsers(acceptedFiles[0]);
                  }}
                >
                  <FormattedMessage id="common.import.verb" />
                </Button>
              </div>

              <div
                className={clsx(
                  "col-start-1 row-start-1 flex flex-col items-center justify-center text-center",
                  importState !== "uploading" && "invisible",
                )}
              >
                <ProgressSpinner className="!m-6" />

                <p className="mt-4 font-bold">
                  <FormattedMessage id="common.importing" />
                  ...
                </p>
              </div>

              <div
                className={clsx(
                  "col-start-1 row-start-1 flex flex-col items-center justify-center text-center",
                  importState !== "completed" && "invisible",
                )}
              >
                <div className="flex size-14 items-center justify-center rounded-full bg-green-700/10">
                  <FontAwesomeIcon
                    icon={faCheck}
                    size="2x"
                    className="text-green-700"
                  />
                </div>

                <p className="mt-4 font-bold">
                  <FormattedMessage id="common.import-completed" />
                </p>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};
