import { FormattedMessage, useIntl } from "react-intl";

import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { Formik } from "formik";

import { VendorType } from "../../../../modules/customer/models/Access";

import { useToaster } from "../../../../hooks/common/useToaster";
import { useSelectedFacility } from "../../../../hooks/swr/useSelectedFacility";
import { useVendorType } from "../../../../hooks/swr/useVendorType";

import { updateFacility } from "../../../../modules/customer/services/FacilityService";

import { Button } from "../../../../components/Button";
import { TextInput } from "../../../../components/TextInput";
import { DefaultFacilityAccessTimeDetails } from "../../../../modules/customer/components/DefaultFacilityAccessTimeDetails";
import { QTFacilityAccessTimeDetails } from "../../../../modules/customer/components/QTFacilityAccessTimeDetails";

import { useSelectedFacilityId } from "../../../../recoil/selectedFacilityIdState";

export const FacilityManagementSettings = () => {
  const { formatMessage } = useIntl();
  const toaster = useToaster();
  const selectedFacilityId = useSelectedFacilityId();
  const { selectedFacility, mutate } = useSelectedFacility();

  const { vendorType, isLoading } = useVendorType(selectedFacilityId);

  if (isLoading || !selectedFacilityId || !selectedFacility) {
    return null;
  }

  const { pincodeSuffix } = selectedFacility;

  return (
    <>
      <h3>
        {vendorType === VendorType.Default ? (
          <FormattedMessage
            id="facility-settings.doors.default-vendor.title"
            defaultMessage="Administrera pinkoder"
          />
        ) : vendorType === VendorType.QT ? (
          <FormattedMessage
            id="facility-settings.doors.qt-vendor.title"
            defaultMessage="Styr anläggningens dörrar"
          />
        ) : null}
      </h3>
      {vendorType === VendorType.Default ? (
        <p>
          <FormattedMessage
            id="facility-settings.doors.default-vendor.temporary.info"
            defaultMessage="Administrera pinkoder för access till hallen. Observera att enbart en pinkod kan vara aktiv åt gången och behöver bestå av 4 siffror."
          />
        </p>
      ) : vendorType === VendorType.QT ? (
        <>
          <h5 className="mt-3">
            <FormattedMessage
              id="facility-settings.doors.qt-vendor.temporary"
              defaultMessage="Entrédörr - tillfällig"
            />
          </h5>
          <p>
            <FormattedMessage
              id="facility-settings.doors.qt-vendor.temporary.info"
              defaultMessage="Låt ytterdörren vara öppen under en begränsad tid."
            />
          </p>
        </>
      ) : null}

      <div className="mt-6">
        {vendorType === VendorType.Default ? (
          <DefaultFacilityAccessTimeDetails facilityId={selectedFacilityId} />
        ) : vendorType === VendorType.QT ? (
          <QTFacilityAccessTimeDetails facilityId={selectedFacilityId} />
        ) : null}
      </div>

      <hr className="my-8" />

      <Formik
        enableReinitialize
        initialValues={{
          pincodeSuffix,
        }}
        onSubmit={async values => {
          try {
            const { data: updatedFacility } = await updateFacility({
              pincodeSuffix: values.pincodeSuffix,
              address: selectedFacility?.address
                ? {
                    country: selectedFacility?.address?.country ?? "",
                    city: selectedFacility?.address?.city ?? "",
                    street: selectedFacility?.address?.street ?? "",
                    streetNumber: selectedFacility?.address?.streetNumber ?? "",
                    postalCode: selectedFacility?.address?.postalCode ?? "",
                  }
                : {
                    country: "",
                    city: "",
                    street: "",
                    streetNumber: "",
                    postalCode: "",
                  },
              description: selectedFacility?.description ?? "",
              email: selectedFacility?.email ?? "",
              id: selectedFacility?.id ?? "",
              logo: selectedFacility?.logo ?? "",
              name: selectedFacility?.name ?? "",
              phone: selectedFacility?.phone ?? "",
              shortMessage: selectedFacility?.shortMessage ?? "",
              website: selectedFacility?.website ?? "",
              communicationSettings:
                selectedFacility?.communicationSettings ?? {
                  showShortMessageInBookingConfirmationEmail: false,
                },
            });

            mutate(updatedFacility);
            toaster.toastSuccess.changesSaved();
          } catch {
            toaster.toastError.unknown();
          }
        }}
      >
        {props => (
          <>
            <h3>
              <FormattedMessage id="common.pincode-suffix" />
            </h3>

            <form
              onSubmit={props.handleSubmit}
              onReset={props.handleReset}
              className="mt-6"
            >
              <TextInput
                name="pincodeSuffix"
                maxLength={5}
                onChange={props.handleChange}
                value={props.values.pincodeSuffix}
                className="max-w-prose"
                label={formatMessage({
                  id: "facility-settings.doors.pincode-suffix.label",
                })}
              />

              <div className="mt-5 flex gap-4">
                <Button
                  disabled={!props.dirty || props.isSubmitting}
                  buttonType="reset"
                  translationName="common.reset"
                  size="small"
                />
                <Button
                  disabled={!props.dirty || props.isSubmitting}
                  buttonType="submit"
                  type="primary"
                  translationName="common.save-changes"
                  size="small"
                  icon={faCheck}
                />
              </div>
            </form>
          </>
        )}
      </Formik>
    </>
  );
};
