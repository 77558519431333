import { DateTime } from "luxon";

import type {
  OpenBooking,
  OpenBookingApiResponse,
} from "../models/OpenBookings";
import type { IBooking } from "../modules/checkout/models/Booking";

import { get } from "./apiClient";

const API_VERSION = "v1";
const API_SERVICE = "/public/open-bookings";
const API_ENDPOINT = `/${API_VERSION}${API_SERVICE}`;

export const getOpenBookings = async (params: { bookingId?: IBooking["id"] }) =>
  get<{ result: OpenBookingApiResponse[]; nextId: OpenBooking["id"] | null }>({
    endpoint: `${API_ENDPOINT}`,
    urlSearchParams: params,
  }).then(data => ({
    result: data.result.map(formatOpenBooking),
    nextId: data.nextId,
  }));

const formatOpenBooking = (data: OpenBookingApiResponse): OpenBooking => ({
  ...data,
  startTime: DateTime.fromISO(data.startTime),
  endTime: DateTime.fromISO(data.endTime),
  automaticCancellationTime: DateTime.fromISO(data.automaticCancellationTime),
  participants: data.participants.map(participant => ({
    ...participant,
    paymentDate: participant.paymentDate
      ? DateTime.fromISO(participant.paymentDate)
      : null,
  })),
});
