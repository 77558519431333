import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import useSWR from "swr";

import { CoinType } from "../../../../modules/player/models/Coins";

import { useSelectedFacility } from "../../../../hooks/swr/useSelectedFacility";
import { useCurrencyFormat } from "../../../../hooks/useCurrencyFormat";

import { adminGetCoinsForFacility } from "../../../../modules/player/services/CoinsService";

import { CoinsTable } from "./components/CoinsTable";

export const AdminFacilitySettingsCoins = () => {
  const { formatMessage } = useIntl();
  const { selectedFacility } = useSelectedFacility();

  const { data, isLoading } = useSWR(
    selectedFacility ? ["adminGetCoinsForFacility", selectedFacility.id] : null,
    ([, facilityId]) => adminGetCoinsForFacility(facilityId),
    {
      fallbackData: [],
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const sums = useMemo(() => {
    return data.reduce(
      (acc, { amount, type }) => {
        acc[type] += amount;
        acc.total += amount;

        return acc;
      },
      { [CoinType.COIN]: 0, [CoinType.ALLOWANCE]: 0, total: 0 },
    );
  }, [data]);

  if (!selectedFacility) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col gap-5 sm:grid sm:grid-cols-3">
        {isLoading ? (
          Array.from({ length: 3 }).map((_, i) => (
            <Box key={i} heading="..." number={Infinity} />
          ))
        ) : (
          <>
            <Box
              heading={formatMessage({ id: "coin.type.coin" })}
              number={sums[CoinType.COIN]}
            />
            <Box
              heading={formatMessage({ id: "coin.type.allowance" })}
              number={sums[CoinType.ALLOWANCE]}
            />
            <Box
              heading={formatMessage({ id: "common.in-total" })}
              number={sums.total}
            />
          </>
        )}
      </div>

      <div className="mt-6 lg:mt-10">
        {!isLoading && <CoinsTable data={data} />}
      </div>
    </>
  );
};

const Box = ({ heading, number }: { heading: string; number: number }) => {
  const { cf } = useCurrencyFormat("Coins");

  return (
    <div className="rounded border border-gray-50 p-6">
      <span className="text-lg font-semibold leading-none text-gray-500">
        {heading}
      </span>
      <div className="mt-3 flex flex-wrap items-end gap-x-3 gap-y-1 md:mt-5 lg:mt-8">
        <span className="overflow-x-clip text-ellipsis whitespace-nowrap text-2xl font-bold !leading-5 text-pureblack md:text-4xl md:!leading-6 lg:text-6xl lg:!leading-9">
          {cf(Math.round(number * 100) / 100)}
        </span>
        <span className="font-semibold leading-none text-gray-700">
          <FormattedMessage id="common.active-plural" />
        </span>
      </div>
    </div>
  );
};
