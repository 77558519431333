import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import clsx from "clsx";
import { DateTime } from "luxon";
import useSWR from "swr";

import type { CompletedOrderByBooking } from "../../../../../../../models/completedOrder";
import { type IBooking } from "../../../../../models/Booking";

import { useToaster } from "../../../../../../../hooks/common/useToaster";
import { useIsSuperAdmin } from "../../../../../../../hooks/permissions";
import { useCurrencyFormat } from "../../../../../../../hooks/useCurrencyFormat";
import { useDateFormat } from "../../../../../../../hooks/useDateFormat";

import { getCompletedOrdersByBooking } from "../../../../../../../services/facilityBookingsService";
import { refundOrderPayment } from "../../../../../../../services/facilityOrdersService";
import { superAdminFefundOrderPayment } from "../../../../../../../services/superAdminOrdersService";

import { Button } from "../../../../../../../components/Button";
import { Checkbox } from "../../../../../../../components/Checkbox";
import { ConfirmationDialog } from "../../../../../../../components/ConfirmationDialog";
import { ProgressSpinner } from "../../../../../../../components/ProgressSpinner";

import { getPaymentMethodText } from "../../../../../../../utils/getPaymentMethodText";

export const Payments = ({ booking }: { booking: IBooking }) => {
  const { formatMessage } = useIntl();
  const toaster = useToaster();
  const isSuperAdmin = useIsSuperAdmin();

  const { data, isLoading, mutate } = useSWR(
    ["payments", booking.facilityId, booking.id],
    ([, facilityId, bookingId]) =>
      getCompletedOrdersByBooking(facilityId, bookingId),
  );

  const { df } = useDateFormat(booking.facilityId);
  const { cf } = useCurrencyFormat(booking.facility.localization?.currencyCode);
  const { cf: cfCoins } = useCurrencyFormat("Coins");

  const [refundConfirmation, setRefundConfirmation] = useState<{
    show?: boolean;
    loading?: boolean;
    orderId?: CompletedOrderByBooking["orderId"];
    paymentId?: CompletedOrderByBooking["payments"][number]["id"];
  }>({});

  const [refundFee, setRefundFee] = useState(false);

  useEffect(() => {
    setRefundFee(false);
  }, [refundConfirmation.show]);

  if (isLoading || !data) {
    return <ProgressSpinner className="m-10" />;
  }

  return (
    <>
      <h3>
        <FormattedMessage id="common.payments" />
      </h3>

      <div className="mt-8 divide-y *:py-4 first:*:pt-0 last:*:pb-0">
        {booking.participants.map(participant => {
          const completedOrder = data.find(
            completedOrder => completedOrder.userId === participant.id,
          );

          return (
            <div key={participant.id}>
              <h4 className={clsx(!isSuperAdmin && "mb-2")}>
                {participant.displayName}
              </h4>

              {isSuperAdmin && completedOrder && (
                <p className="mb-2">Order ID: {completedOrder?.orderId}</p>
              )}

              {!completedOrder || completedOrder?.payments.length === 0 ? (
                <FormattedMessage id="common.no-payments" />
              ) : (
                <table className="w-full table-fixed text-base">
                  <thead className="font-semibold">
                    <tr className="table-row *:px-2 first:*:pl-0 last:*:pr-0">
                      <td>
                        <FormattedMessage id="common.status" />
                      </td>
                      <td>
                        <FormattedMessage id="common.date-and-time" />
                      </td>
                      <td>
                        <FormattedMessage id="series.paid" />
                      </td>
                      <td>
                        <FormattedMessage id="receipt.refund" />
                      </td>
                      <td></td>
                    </tr>
                  </thead>

                  <tbody>
                    {completedOrder.payments.map(payment => {
                      const isRefundable = !(
                        (payment.status === "Refunded" && !isSuperAdmin) ||
                        (!!payment.refundedAmount &&
                          payment.paidAmount.valueInclTax <=
                            payment.refundedAmount?.valueInclTax)
                      );
                      return (
                        <tr
                          key={payment.id}
                          className="table-row *:px-2 *:py-1 first:*:pl-0 last:*:pr-0"
                        >
                          <td>
                            {payment.status === "Paid" ? (
                              <FormattedMessage id="receipts.translation.FullyCompleted.statuses" />
                            ) : payment.status === "Refunded" ? (
                              <FormattedMessage id="receipts.translation.Refunded.statuses" />
                            ) : payment.status === "RemainderDebtCollected" ? (
                              <FormattedMessage id="common.remaining-cost-paid" />
                            ) : null}
                          </td>
                          <td>{df(payment.created, DateTime.DATETIME_MED)}</td>
                          <td>
                            {payment.paymentProvider === "Coins"
                              ? `${cfCoins(payment.paidAmount.valueInclTax)}`
                              : cf(payment.paidAmount.valueInclTax)}{" "}
                            {getPaymentMethodText({
                              provider: payment.paymentProvider,
                              instrument: payment.paymentInstrument,
                            })}
                          </td>
                          {payment.refundedAmount ? (
                            <td className="text-nowrap">
                              {payment.paymentProvider === "Coins"
                                ? `${cfCoins(payment.refundedAmount.valueInclTax)} Coins`
                                : cf(payment.refundedAmount.valueInclTax)}
                              , {df(payment.updated, DateTime.DATETIME_MED)}
                            </td>
                          ) : (
                            <td></td>
                          )}
                          <td className="flex justify-end">
                            <Button
                              disabled={!isRefundable}
                              type="primary"
                              size="small"
                              translationName={
                                isRefundable
                                  ? "common.refund.verb"
                                  : "receipts.translation.Refunded.statuses"
                              }
                              onClick={() => {
                                setRefundConfirmation({
                                  show: true,
                                  orderId: completedOrder.orderId,
                                  paymentId: payment.id,
                                });
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          );
        })}
      </div>

      {refundConfirmation.show && (
        <ConfirmationDialog
          visible
          confirmButtonType="danger"
          loading={refundConfirmation.loading}
          title={formatMessage({
            id: "common.refund.verb",
          })}
          onHide={() => setRefundConfirmation({ show: false })}
          onCancel={() => setRefundConfirmation({ show: false })}
          onSubmit={async () => {
            setRefundConfirmation(v => ({ ...v, loading: true }));

            if (!refundConfirmation.orderId || !refundConfirmation.paymentId) {
              toaster.toastError.unknown();
              setRefundConfirmation(v => ({ ...v, loading: false }));
              return;
            }

            try {
              if (isSuperAdmin) {
                await superAdminFefundOrderPayment(
                  refundConfirmation.orderId,
                  refundConfirmation.paymentId,
                  refundFee,
                );
              } else {
                await refundOrderPayment(
                  booking.facilityId,
                  refundConfirmation.orderId,
                  refundConfirmation.paymentId,
                );
              }

              toaster.toastSuccess.message("receipt.refund");

              setRefundConfirmation({});
              mutate();
            } catch {
              toaster.toastError.unknown();

              setRefundConfirmation(v => ({ ...v, loading: false }));
            }
          }}
        >
          {isSuperAdmin && (
            <div className="flex justify-center">
              <Checkbox
                label={formatMessage({ id: "common.including-fee" })}
                checked={refundFee}
                onChange={e => setRefundFee(e.target.checked)}
              />
            </div>
          )}
        </ConfirmationDialog>
      )}
    </>
  );
};
