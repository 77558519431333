import { useCallback } from "react";
import { FormattedMessage } from "react-intl";

import clsx from "clsx";

import { PaymentStatus } from "../../models/payment";

import {
  getCurrentOrderPayment,
  getOrderPaymentForSwedbankPaySwish,
} from "../../services/myOrdersService";

import { Confirmation } from "./Confirmation";
import { BookingOrderDetails } from "./OrderDetails/BookingOrderDetails";
import { EventOrderDetails } from "./OrderDetails/EventOrderDetails";
import { MembershipOrderDetails } from "./OrderDetails/MembershipOrderDetails";
import { SeriesOrderDetails } from "./OrderDetails/SeriesOrderDetails";
import { PaymentDetails } from "./PaymentDetails/PaymentDetails";
import { PaymentMethods } from "./PaymentMethods/PaymentMethods";
import { SwedbankPayPayment } from "./PaymentMethods/swedbank-pay/SwedbankPay";
import { SplitPayment } from "./SplitPayment/SplitPayment";
import { useCheckoutState } from "./context/CheckoutContext";

export const CheckoutView = () => {
  const { orderType, order, checkoutStatus, dataStatus, action } =
    useCheckoutState();

  const swishRedirectCallbackOrderPayment = useCallback(async () => {
    const currentOrderPayment = await getCurrentOrderPayment(order.id);

    if (currentOrderPayment.paymentStatus !== PaymentStatus.Pending) {
      throw new Error("Payment is not pending");
    }

    if (
      currentOrderPayment.paymentMethod.provider !== "SwedbankPay" ||
      currentOrderPayment.paymentMethod.instrument !== "Swish"
    ) {
      throw new Error("Payment method is not Swish");
    }

    return await getOrderPaymentForSwedbankPaySwish(
      order.id,
      currentOrderPayment.orderPaymentId,
    );
  }, [order.id]);

  if (dataStatus === "loading") {
    return null;
  }

  if (dataStatus === "error") {
    return (
      <div className="bg-purewhite px-10 py-16">
        <CheckoutViewHeading className="text-error">
          <FormattedMessage id="general.error.contact.support" />
        </CheckoutViewHeading>
      </div>
    );
  }

  if (checkoutStatus === "paymentCompleted" || order.isPaid) {
    return <Confirmation />;
  }

  if (action === "swishRedirectCallback") {
    return (
      <SwedbankPayPayment
        createOrderPayment={swishRedirectCallbackOrderPayment}
      />
    );
  }

  return (
    <div className="bg-purewhite px-5 py-8 lg:px-10 lg:py-16">
      <div className="grid gap-8 lg:grid-cols-[1fr,1px,1fr] lg:gap-16">
        <div className="space-y-8 lg:space-y-10">
          {["booking", "open-booking"].includes(orderType) && (
            <BookingOrderDetails />
          )}
          {orderType === "event" && <EventOrderDetails />}
          {orderType === "series" && <SeriesOrderDetails />}
          {orderType === "membership" && <MembershipOrderDetails />}

          {order.isSplittable && <SplitPayment />}
        </div>

        <hr className="hidden h-full w-px bg-gray-200 lg:block" />

        <div className="space-y-8 lg:space-y-10">
          <PaymentDetails />

          <PaymentMethods />
        </div>
      </div>
    </div>
  );
};

export const CheckoutViewHeading = ({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) => (
  <h2 className={clsx("text-2xl lg:text-5xl", className)}>{children}</h2>
);
