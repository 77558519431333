import { Fragment, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import {
  type Table as ReactTable,
  type Row,
  flexRender,
} from "@tanstack/react-table";
import { DateTime } from "luxon";
import { Skeleton } from "primereact/skeleton";

import type { DateOnly } from "../models/DateOnly";
import type { TimeOnly } from "../models/TimeOnly";
import type { FacilityWithUtc } from "../modules/customer/models/Facility";

import { useCurrencyFormat } from "../hooks/useCurrencyFormat";
import { useDateFormat } from "../hooks/useDateFormat";

const skeletonWidthMap = [100, 65, 80, 65];

export const Table = <T extends object>({
  table,
  isLoading,
  RowComponent = TableDefaultRowComponent,
}: {
  table: ReactTable<T>;
  isLoading?: boolean;
  RowComponent?: ({ row }: { row: Row<T> }) => ReactNode;
}) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full text-left">
        <thead className="text-nowrap text-gray-700">
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} className="px-4 py-5">
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {isLoading ? (
            table.getHeaderGroups().map(headerGroup => (
              <Fragment key={headerGroup.id}>
                {[...Array(10)].map((_, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className="relative [&>td]:border-l-2 [&>td]:border-l-transparent first:[&>td]:border-0 [&>td]:even:border-l-purewhite [&>td]:even:bg-white"
                  >
                    {headerGroup.headers.map((header, index) => (
                      <td key={header.id} className="p-4">
                        <Skeleton
                          className="!h-5"
                          width={`${skeletonWidthMap[(index + rowIndex) % 4]}%`}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </Fragment>
            ))
          ) : table.getRowCount() === 0 ? (
            <tr>
              <td colSpan={100}>
                <div className="my-32 flex justify-center">
                  <div className="max-w-prose text-balance text-center">
                    <h3>
                      <FormattedMessage id="common.table.empty-result.heading" />
                    </h3>
                    <p className="mt-2 text-base text-gray-700">
                      <FormattedMessage id="common.table.empty-result.text" />
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          ) : (
            table
              .getRowModel()
              .rows.map(row => <RowComponent key={row.id} row={row} />)
          )}
        </tbody>
      </table>
    </div>
  );
};

export const TableDefaultRowComponent = <T extends object>({
  row,
  children,
}: React.PropsWithChildren<{
  row: Row<T>;
}>) => {
  return (
    <tr
      key={row.id}
      className="relative [&>td]:border-l-2 [&>td]:border-l-transparent first:[&>td]:border-0 [&>td]:even:border-l-purewhite [&>td]:even:bg-white"
    >
      {row.getVisibleCells().map(cell => (
        <td key={cell.id} className="p-4">
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}

      {children}
    </tr>
  );
};

export const TableDateCell = ({
  value,
  facilityId,
  dateFormat,
}: {
  value: DateTime | DateOnly | TimeOnly;
  facilityId: FacilityWithUtc["id"] | undefined | null;
  dateFormat: Intl.DateTimeFormatOptions;
}) => {
  const { df } = useDateFormat(facilityId);

  return <div className="whitespace-nowrap">{df(value, dateFormat)}</div>;
};

export const TableCurrencyCell = ({
  value,
  currency,
  options,
}: {
  value: number;
  currency: Parameters<typeof useCurrencyFormat>[0];
  options?: Parameters<typeof useCurrencyFormat>[1];
}) => {
  const { cf } = useCurrencyFormat(currency, options);

  return <div className="whitespace-nowrap text-right">{cf(value)}</div>;
};
