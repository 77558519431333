import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { DateTime } from "luxon";
import { type KeyedMutator } from "swr";

import { IBooking } from "../../../../models/Booking";

import { adminGetPlayerPath } from "../../../../../../helpers/pathHelpers";

import { useSeries } from "../../../../../../hooks/swr/useSeries";
import { useDateFormat } from "../../../../../../hooks/useDateFormat";

import { ProgressSpinner } from "../../../../../../components/ProgressSpinner";
import { Comment } from "./components/Comment";
import { DataGrid } from "./components/DataGrid";
import { Players } from "./components/Players";

interface Props {
  booking: IBooking;
  onSubmitted: (refreshView?: boolean, isEditDialogOpen?: boolean) => void;
  mutateBooking: KeyedMutator<IBooking>;
}

export const AdminCalendarSeriesDetails = ({
  booking,
  mutateBooking,
  onSubmitted,
}: Props) => {
  const { df } = useDateFormat(booking.facilityId);

  const { series, isLoading } = useSeries(
    booking.externalServiceId,
    "participants",
  );

  if (isLoading) {
    return <ProgressSpinner />;
  }

  if (!series) {
    return null;
  }

  return (
    <>
      <DataGrid
        data={[
          {
            heading: <FormattedMessage id="common.date-and-time" />,
            text: (
              <>
                {df(booking.startTime, DateTime.DATETIME_MED)} -{" "}
                {df(booking.endTime, DateTime.TIME_SIMPLE)}
              </>
            ),
          },
          {
            heading: <FormattedMessage id="common.booked-by" />,
            text: (
              <Link to={adminGetPlayerPath(booking.bookedBy.id)}>
                {booking.bookedBy.displayName}
              </Link>
            ),
          },
          {
            heading: <FormattedMessage id="common.court" />,
            text: booking.court.name,
          },
          {
            heading: <FormattedMessage id="common.created" />,
            text:
              booking.createdDate &&
              df(booking.createdDate, DateTime.DATETIME_MED),
          },
        ]}
      />

      <hr className="mt-4" />

      <Players
        booking={booking}
        mutateBooking={mutateBooking}
        onSubmitted={onSubmitted}
      />

      <hr className="mt-4" />

      <Comment
        booking={booking}
        mutateBooking={mutateBooking}
        onSubmitted={onSubmitted}
      />
    </>
  );
};
