import { DateTime } from "luxon";

import type {
  OpenBooking,
  OpenBookingApiResponse,
} from "../models/OpenBookings";

import { get, post } from "./apiClient";

const API_VERSION = "v1";
const API_SERVICE = "/open-bookings";
const API_ENDPOINT = `/${API_VERSION}${API_SERVICE}`;

export const getOpenBooking = async (
  openBookingId: OpenBooking["id"],
): Promise<OpenBooking> =>
  get<OpenBookingApiResponse>({
    endpoint: `${API_ENDPOINT}/${openBookingId}`,
  }).then(formatOpenBooking);

export const leaveOpenBooking = async (openBookingId: OpenBooking["id"]) =>
  post({
    endpoint: `${API_ENDPOINT}/${openBookingId}/leave`,
    body: {},
    parseDataAs: "none",
  });

const formatOpenBooking = (data: OpenBookingApiResponse): OpenBooking => ({
  ...data,
  startTime: DateTime.fromISO(data.startTime),
  endTime: DateTime.fromISO(data.endTime),
  automaticCancellationTime: DateTime.fromISO(data.automaticCancellationTime),
  participants: data.participants.map(participant => ({
    ...participant,
    paymentDate: participant.paymentDate
      ? DateTime.fromISO(participant.paymentDate)
      : null,
  })),
});
