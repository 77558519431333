import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  faChevronDown,
  faChevronUp,
  faCopy,
  faPaste,
} from "@fortawesome/pro-regular-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { Form, Formik, type FormikProps } from "formik";
import useSWR from "swr";

import { VendorType } from "../../../../modules/customer/models/Access";
import type {
  FacilityQTCourt,
  FacilityWithUtc,
} from "../../../../modules/customer/models/Facility";

import { useToaster } from "../../../../hooks/common/useToaster";
import { useVendorType } from "../../../../hooks/swr/useVendorType";

import { reSendAccessEventForFutureBookingsToQT } from "../../../../modules/checkout/services/Booking";
import {
  getFacilityQTCourts,
  getFacilityVendors,
  updateFacilityQTCourts,
  updateFacilityVendors,
} from "../../../../modules/customer/services/FacilityService";

import { Button } from "../../../../components/Button";
import { NumberInput } from "../../../../components/NumberInput";
import { SubmitResetButtons } from "../../../../components/SubmitResetButtons";
import { TextInput } from "../../../../components/TextInput";

export const QT = ({ facilityId }: { facilityId: FacilityWithUtc["id"] }) => {
  const { vendorType, isLoading } = useVendorType(facilityId);

  if (isLoading) {
    return;
  }

  if (vendorType !== VendorType.QT) {
    return <p className="text-red-600">This facility is not set up for QT.</p>;
  }

  return (
    <>
      <h3>
        <FormattedMessage id="superadmin.facility.qt.title" />
      </h3>

      <div className="mt-8">
        <div className="max-w-prose">
          <FormattedMessage id="superadmin.facility.qt.description" />
        </div>
      </div>

      <div className="mt-8">
        <VendorSettingsForm facilityId={facilityId} />

        <hr className="my-10 md:my-20" />

        <CourtsForm facilityId={facilityId} />
      </div>
    </>
  );
};

const VendorSettingsForm = ({
  facilityId,
}: {
  facilityId: FacilityWithUtc["id"];
}) => {
  const { formatMessage } = useIntl();
  const toaster = useToaster();

  const { data, mutate } = useSWR(
    facilityId ? ["facilityVendors", facilityId] : undefined,
    ([, facilityId]) => getFacilityVendors(facilityId),
  );

  if (!data || data.length !== 1) {
    return;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      onSubmit={async values => {
        try {
          const newVendors = await updateFacilityVendors(facilityId, values);
          mutate(newVendors, false);

          toaster.toastSuccess.changesSaved();
        } catch (error) {
          console.log(error);
          toaster.toastError.unknown();
        }
      }}
    >
      {props => (
        <form
          onSubmit={props.handleSubmit}
          onReset={props.handleReset}
          className="max-w-prose space-y-8"
        >
          <TextInput
            name="0.settings.bearer"
            value={props.values[0].settings?.bearer}
            onChange={props.handleChange}
            label={formatMessage({ id: "superadmin.facility.qt.bearer.label" })}
          />

          <TextInput
            name="0.settings.doorReference"
            value={props.values[0].settings?.doorReference}
            onChange={props.handleChange}
            label={formatMessage({
              id: "superadmin.facility.qt.doorReference.label",
            })}
          />

          <SubmitResetButtons disabled={!props.dirty || props.isSubmitting} />
        </form>
      )}
    </Formik>
  );
};

const CourtsForm = ({ facilityId }: { facilityId: FacilityWithUtc["id"] }) => {
  const toaster = useToaster();

  const { data, mutate } = useSWR(
    facilityId ? ["facilityQTCourts", facilityId] : undefined,
    ([, facilityId]) => getFacilityQTCourts(facilityId),
  );

  const [isSyncingWithQT, setIsSyncingWithQT] = useState(false);

  const courts = useMemo(
    () =>
      data?.map(court => ({
        ...court,
        vendorReferences: court.vendorReferences.map(vendorReference => ({
          ...vendorReference,
          timeOffsetLights: {
            start: Math.abs(
              Math.round(vendorReference.timeOffsetLights.start / 60),
            ),
            end: Math.abs(
              Math.round(vendorReference.timeOffsetLights.end / 60),
            ),
          },
          timeOffsetDoor: {
            start: Math.abs(
              Math.round(vendorReference.timeOffsetDoor.start / 60),
            ),
            end: Math.abs(Math.round(vendorReference.timeOffsetDoor.end / 60)),
          },
        })),
      })),
    [data],
  );

  const syncWithQT = useCallback(async () => {
    setIsSyncingWithQT(true);

    try {
      await reSendAccessEventForFutureBookingsToQT(facilityId);
      toaster.toastSuccess.message(
        "superadmin.facility.qt.courts.toast.success.sync-with-qt",
      );
    } catch (error) {
      console.log(error);
      toaster.toastError.unknown();
    } finally {
      setIsSyncingWithQT(false);
    }
  }, [facilityId, toaster.toastError, toaster.toastSuccess]);

  if (!courts || courts.length === 0) {
    return;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={courts}
      onSubmit={async values => {
        const courts = values.map(court => ({
          ...court,
          vendorReferences: court.vendorReferences.map(vendorReference => ({
            ...vendorReference,
            timeOffsetLights: {
              start: -vendorReference.timeOffsetLights.start * 60,
              end: vendorReference.timeOffsetLights.end * 60,
            },
            timeOffsetDoor: {
              start: -vendorReference.timeOffsetDoor.start * 60,
              end: vendorReference.timeOffsetDoor.end * 60,
            },
          })),
        }));

        try {
          const updatedQTCourts = await updateFacilityQTCourts(
            facilityId,
            courts,
          );
          mutate(updatedQTCourts, false);

          toaster.toastSuccess.changesSaved();
        } catch (error) {
          console.log(error);
          toaster.toastError.unknown();
        }
      }}
    >
      {props => {
        const completedCourts = props.values.filter(court =>
          isCourtCompleted(court),
        );

        return (
          <>
            <div className="flex items-center justify-between">
              <h3>
                <FormattedMessage id="superadmin.facility.qt.courts.title" />
              </h3>

              <span
                className={clsx(
                  "px-2 py-1 font-bold",
                  completedCourts.length >= courts.length
                    ? "bg-green-700/10 text-green-700"
                    : "bg-red-600/10 text-red-600",
                )}
              >
                {completedCourts.length} / {courts.length}{" "}
                <FormattedMessage id="superadmin.facility.qt.courts.courts-finished" />
              </span>
            </div>

            <Form className="mt-7">
              <div className="mb-5 space-y-6 md:mb-10">
                {props.values.map((court, index) => {
                  const isCompleted = isCourtCompleted(court);

                  return (
                    <Court
                      key={court.id}
                      court={court}
                      index={index}
                      isCompleted={isCompleted}
                      formik={props}
                    />
                  );
                })}
              </div>

              <SubmitResetButtons
                disabled={!props.dirty || props.isSubmitting}
              />
              <div className="mt-4 md:flex md:justify-end">
                <Button
                  loading={isSyncingWithQT}
                  disabled={
                    props.isSubmitting ||
                    completedCourts.length < courts.length ||
                    isSyncingWithQT
                  }
                  type="default"
                  onClick={() => syncWithQT()}
                  className="w-full md:w-auto"
                >
                  <FormattedMessage id="superadmin.facility.qt.courts.sync-with-qt" />
                </Button>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

const Court = ({
  court,
  index,
  isCompleted,
  formik,
}: {
  court: FacilityQTCourt;
  index: number;
  isCompleted: boolean;
  formik: FormikProps<FacilityQTCourt[]>;
}) => {
  const { formatMessage } = useIntl();

  const [isExpanded, setIsExpanded] = useState(!isCompleted);
  const [isCopiedToAllCourts, setIsCopiedToAllCourts] = useState(false);
  const [isCopiedFromPreviousCourt, setIsCopiedFromPreviousCourt] =
    useState(false);

  const previousCourt = useMemo(
    () => formik.values.at(index - 1),
    [formik.values, index],
  );

  useEffect(() => {
    setIsCopiedToAllCourts(false);
  }, [court.vendorReferences]);

  useEffect(() => {
    setIsCopiedFromPreviousCourt(false);
  }, [previousCourt?.vendorReferences]);

  const copyToAllCourts = useCallback(
    (courtIndex: number) => {
      const updatedCourts = formik.values.map((court, index) => {
        if (index === courtIndex) {
          return court;
        }

        return {
          ...court,
          vendorReferences: court.vendorReferences.map(vendorReference => ({
            ...vendorReference,
            timeOffsetLights:
              formik.values[courtIndex].vendorReferences[0].timeOffsetLights,
            timeOffsetDoor:
              formik.values[courtIndex].vendorReferences[0].timeOffsetDoor,
          })),
        };
      });

      formik.setValues(updatedCourts);
      setIsCopiedToAllCourts(true);
    },
    [formik],
  );

  const copyFromPreviousCourt = useCallback(
    (courtIndex: number) => {
      const updatedCourts = formik.values.map((court, index) => {
        if (index !== courtIndex) {
          return court;
        }

        return {
          ...court,
          vendorReferences: court.vendorReferences.map(vendorReference => ({
            ...vendorReference,
            timeOffsetLights:
              formik.values[courtIndex - 1].vendorReferences[0]
                .timeOffsetLights,
            timeOffsetDoor:
              formik.values[courtIndex - 1].vendorReferences[0].timeOffsetDoor,
          })),
        };
      });

      formik.setValues(updatedCourts);
      setIsCopiedFromPreviousCourt(true);
    },
    [formik],
  );

  const actionButtons = (
    <>
      {typeof court.vendorReferences[0]?.timeOffsetDoor.start !== "undefined" &&
        typeof court.vendorReferences[0]?.timeOffsetDoor.end !== "undefined" &&
        typeof court.vendorReferences[0]?.timeOffsetLights.start !==
          "undefined" &&
        typeof court.vendorReferences[0]?.timeOffsetLights.end !==
          "undefined" && (
          <button
            type="button"
            onClick={e => {
              e.stopPropagation();
              copyToAllCourts(index);
            }}
            className={clsx(
              "hover:underline",
              isCopiedToAllCourts && "text-primary",
            )}
          >
            <FontAwesomeIcon
              icon={isCopiedToAllCourts ? faCheck : faCopy}
              className="mr-1 inline-block"
            />

            {isCopiedToAllCourts ? (
              <FormattedMessage id="superadmin.facility.qt.courts.copied-to-all-courts" />
            ) : (
              <FormattedMessage id="superadmin.facility.qt.courts.copy-to-all-courts" />
            )}
          </button>
        )}

      {index > 0 && (
        <button
          type="button"
          onClick={e => {
            e.stopPropagation();
            copyFromPreviousCourt(index);
          }}
          className={clsx(
            "hover:underline",
            isCopiedFromPreviousCourt && "text-primary",
          )}
        >
          <FontAwesomeIcon
            icon={isCopiedFromPreviousCourt ? faCheck : faPaste}
            className="mr-1 inline-block"
          />

          {isCopiedFromPreviousCourt ? (
            <FormattedMessage id="superadmin.facility.qt.courts.copied-from-previous-court" />
          ) : (
            <FormattedMessage id="superadmin.facility.qt.courts.copy-from-previous-court" />
          )}
        </button>
      )}
    </>
  );

  return (
    <details
      open={isExpanded}
      onToggle={e => setIsExpanded(e.currentTarget.open)}
      className="rounded-2xl border border-gray-50"
      key={court.id}
    >
      <summary className="flex w-full cursor-pointer items-center p-4 md:p-6">
        <h4 className="flex min-w-0 items-center gap-2">
          <span
            className={clsx(
              "flex size-5 flex-none items-center justify-center rounded-full border text-xs text-white",
              isCompleted ? "border-green-700 bg-green-700" : "border-gray-50",
            )}
          >
            {isCompleted && <FontAwesomeIcon icon={faCheck} />}
          </span>

          <span className="truncate">{court.name}</span>
        </h4>

        <div className="ml-auto flex items-center">
          {isExpanded && (
            <div className="hidden items-center gap-5 text-sm md:flex">
              {actionButtons}
            </div>
          )}

          <span className="ml-7">
            <FontAwesomeIcon
              icon={isExpanded ? faChevronUp : faChevronDown}
              className="text-lg md:text-2xl"
            />
          </span>
        </div>
      </summary>

      <div className="mt-4 px-4 pb-4 md:px-6 md:pb-6">
        <div className="space-y-2 text-sm md:hidden">{actionButtons}</div>
        <div
          className={clsx(
            "mt-4 grid gap-2 md:mt-0 md:grid md:grid-flow-col md:grid-cols-[1fr_2fr_2fr] md:gap-x-11 md:gap-y-10 xl:pr-48",
          )}
        >
          <TextInput
            name={`${index}.vendorReferences.0.vendorReferenceId`}
            value={court.vendorReferences[0].vendorReferenceId}
            onChange={formik.handleChange}
            label={formatMessage({
              id: "superadmin.facility.qt.courts.vendorReferenceId",
            })}
            placeholder="xx"
            className="md:row-span-2"
          />
          <NumberInput
            name={`${index}.vendorReferences.0.timeOffsetLights.start`}
            value={court.vendorReferences[0].timeOffsetLights.start}
            onChange={formik.handleChange}
            label={formatMessage({
              id: "superadmin.facility.qt.courts.lights.start",
            })}
            placeholder="5"
          />
          <NumberInput
            name={`${index}.vendorReferences.0.timeOffsetLights.end`}
            value={court.vendorReferences[0].timeOffsetLights.end}
            onChange={formik.handleChange}
            label={formatMessage({
              id: "superadmin.facility.qt.courts.lights.end",
            })}
            placeholder="5"
          />
          <NumberInput
            name={`${index}.vendorReferences.0.timeOffsetDoor.start`}
            value={court.vendorReferences[0].timeOffsetDoor.start}
            onChange={formik.handleChange}
            label={formatMessage({
              id: "superadmin.facility.qt.courts.door.start",
            })}
            placeholder="60"
          />
          <NumberInput
            name={`${index}.vendorReferences.0.timeOffsetDoor.end`}
            value={court.vendorReferences[0].timeOffsetDoor.end}
            onChange={formik.handleChange}
            label={formatMessage({
              id: "superadmin.facility.qt.courts.door.end",
            })}
            placeholder="60"
          />
        </div>
      </div>
    </details>
  );
};

const isCourtCompleted = (court: FacilityQTCourt) => {
  return Boolean(
    !!court.vendorReferences[0].vendorReferenceId &&
      typeof court.vendorReferences[0].timeOffsetDoor.start !== "undefined" &&
      typeof court.vendorReferences[0].timeOffsetDoor.end !== "undefined" &&
      typeof court.vendorReferences[0].timeOffsetLights.start !== "undefined" &&
      typeof court.vendorReferences[0].timeOffsetLights.end !== "undefined",
  );
};
