import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { faImage, faPen } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";

import { FacilityUpdateRequest } from "../../../../../modules/customer/models/Facility";

import { useToaster } from "../../../../../hooks/common/useToaster";
import { useAppDispatch } from "../../../../../hooks/store/store";
import { useSelectedFacility } from "../../../../../hooks/swr/useSelectedFacility";
import { useFormValidationSchema } from "../../../../../modules/customer/hooks/useFormValidationSchema";
import { useRefetchFacilityInformation } from "../../../../../modules/customer/hooks/useRefetchFacilityInformation";

import {
  removeFacilityLogo,
  updateFacility,
  uploadFacilityLogo,
} from "../../../../../modules/customer/services/FacilityService";

import { Checkbox } from "../../../../../components/Checkbox";
import { Dialog } from "../../../../../components/Dialog";
import { ProgressSpinner } from "../../../../../components/ProgressSpinner";
import { TextAreaInput } from "../../../../../components/TextAreaInput";
import { TextInput } from "../../../../../components/TextInput";
import ImageInput from "../../../../../components/image/ImageInput";
import { FacilitySubmitResetButtons } from "../../../../../modules/customer/components/FacilitySubmitResetButtons";

import { setSelectedFacility } from "../../../../../store/slices/facilities/facilitiesSlice";

export const GeneralInfo: React.FC = () => {
  const { selectedFacility, mutate } = useSelectedFacility();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { facilityUpdatedCallback, refetchFacilities } =
    useRefetchFacilityInformation();
  const { toastSuccess, toastError } = useToaster();
  const { generalInfoSchema } = useFormValidationSchema();
  const [logoLoading, setLogoLoading] = useState(false);
  const [displayImageModifierDialog, setDisplayImageModifierDialog] =
    useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: selectedFacility?.address
        ? {
            country: selectedFacility?.address?.country ?? "",
            city: selectedFacility?.address?.city ?? "",
            street: selectedFacility?.address?.street ?? "",
            streetNumber: selectedFacility?.address?.streetNumber ?? "",
            postalCode: selectedFacility?.address?.postalCode ?? "",
          }
        : {
            country: "",
            city: "",
            street: "",
            streetNumber: "",
            postalCode: "",
          },
      description: selectedFacility?.description ?? "",
      email: selectedFacility?.email ?? "",
      id: selectedFacility?.id ?? "",
      logo: selectedFacility?.logo ?? "",
      name: selectedFacility?.name ?? "",
      phone: selectedFacility?.phone ?? "",
      pincodeSuffix: selectedFacility?.pincodeSuffix ?? "",
      shortMessage: selectedFacility?.shortMessage ?? "",
      website: selectedFacility?.website ?? "",
      communicationSettings: selectedFacility?.communicationSettings ?? {
        showShortMessageInBookingConfirmationEmail: false,
      },
    },
    validationSchema: generalInfoSchema,

    onSubmit: (data: FacilityUpdateRequest) => {
      facilityUpdatedCallback(async () => {
        try {
          await updateFacility(data);
          toastSuccess.infoSaved();
          refetchFacilities();
          return true;
        } catch (e) {
          console.log(e);
          e.hasValidationErrors
            ? toastError.validation()
            : toastError.saveFailed();
        }
      });
    },
  });

  const logoImageUploader = async (file: File) => {
    setDisplayImageModifierDialog(false);

    try {
      setLogoLoading(true);

      const facility = await uploadFacilityLogo(
        selectedFacility?.id,
        file,
        true,
      );
      toastSuccess.logoChanged();

      dispatch(setSelectedFacility({ facility }));
      mutate(facility);
    } catch {
      toastError.uploadLogoFailed();
      return false;
    } finally {
      setLogoLoading(false);
    }
  };

  const removeFacilityLogoCallback = async () => {
    if (!selectedFacility.logo) return;
    setDisplayImageModifierDialog(false);
    try {
      setLogoLoading(true);
      await removeFacilityLogo(selectedFacility?.id);
      mutate({ ...selectedFacility, logo: "" });
      toastSuccess.facilityLogoRemoved();
    } catch {
      toastError.removeLogoFailed();
    } finally {
      setLogoLoading(false);
    }
  };

  return (
    <div>
      <div className="mb-10 grid grid-cols-[3fr_1fr] gap-10">
        <h3>
          <FormattedMessage id="common.facility.info" />
        </h3>
        <h3 className="invisible pl-10 xl:visible">
          <FormattedMessage id="common.logo" />
        </h3>
      </div>
      <div className="grid grid-cols-[3fr_1fr] gap-10">
        <form
          onSubmit={formik.handleSubmit}
          className="col-span-2 grid grid-cols-2 gap-6 xl:col-span-1"
        >
          {/* NAME EMAIL STREET PHONE*/}
          <TextInput
            className="col-span-2 sm:col-span-1"
            error={formik.errors.name}
            label={intl.formatMessage({ id: "common.name" })}
            name="name"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.name}
          />

          <TextInput
            className="col-span-2 sm:col-span-1"
            error={formik.errors.email}
            label={intl.formatMessage({ id: "common.email" })}
            name="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.email}
          />

          <TextInput
            className="col-span-2 sm:col-span-1"
            error={formik.errors.address?.street}
            label={intl.formatMessage({ id: "common.address.street" })}
            name="address.street"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.address?.street}
          />

          <TextInput
            className="col-span-2 sm:col-span-1"
            error={formik.errors.phone}
            label={intl.formatMessage({ id: "common.phone" })}
            name="phone"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.phone}
          />

          {/* POSTAL-CODE CITY */}
          <div className="col-span-2 grid gap-4 sm:grid-cols-2 lg:col-span-1">
            <TextInput
              error={formik.errors.address?.postalCode}
              label={intl.formatMessage({ id: "common.address.postal-code" })}
              name="address.postalCode"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.address?.postalCode}
            />
            <TextInput
              error={formik.errors.address?.city}
              label={intl.formatMessage({ id: "common.address.city" })}
              name="address.city"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.address?.city}
            />
          </div>

          <div className="hidden lg:col-span-1 lg:block"></div>

          {/* COUNTRY WEBSITE */}
          <TextInput
            className="col-span-2 sm:col-span-1"
            disabled
            error={formik.errors.address?.country}
            label={intl.formatMessage({ id: "common.address.country" })}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.address?.country}
          />

          <TextInput
            className="col-span-2 sm:col-span-1"
            error={formik.errors.website}
            label={intl.formatMessage({ id: "common.website" })}
            name="website"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.website}
          />

          {/* DESCRIPTION */}
          <TextAreaInput
            className="col-span-2"
            rows={6}
            name="description"
            label={intl.formatMessage({ id: "common.description" })}
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          {/* SHORT MESSAGE */}
          <div className="col-span-2 mt-10 flex flex-col gap-4">
            <h3 className="mb-4 text-xl">
              <FormattedMessage id="admin.facility.settings.shortMessage.title" />
            </h3>
            <p>
              <FormattedMessage id="admin.facility.settings.shortMessage.description" />
            </p>

            <p className="-mb-3 mt-2 text-gray-400">
              <FormattedMessage
                id="admin.facility.settings.shortMessage.counter"
                values={{
                  count: formik.values.shortMessage?.length || 0,
                  maxCount: 150,
                }}
              />
            </p>
            <TextInput
              className="mt-2 w-full"
              name="shortMessage"
              value={formik.values.shortMessage}
              onChange={formik.handleChange}
              error={formik.errors.shortMessage}
            />

            {formik.values.shortMessage && (
              <Checkbox
                name="communicationSettings.showShortMessageInBookingConfirmationEmail"
                checked={
                  formik.values.communicationSettings
                    .showShortMessageInBookingConfirmationEmail
                }
                onChange={formik.handleChange}
                label={intl.formatMessage({
                  id: "admin.facility.settings.emailShortMessage.checkbox.label",
                })}
              />
            )}
          </div>
        </form>

        {/* IMAGE CONTAINER */}
        <div className="order-first col-span-2 xl:order-none xl:col-span-1">
          <div className="xl:border-l xl:pl-10">
            <div className="h-52 w-52 border">
              {logoLoading ? (
                <ProgressSpinner />
              ) : (
                <div
                  onClick={() => setDisplayImageModifierDialog(true)}
                  className="relative grid h-full w-full cursor-pointer content-center justify-center"
                >
                  {formik.values.logo ? (
                    <img
                      src={formik.values.logo}
                      alt="Logotyp"
                      className="object-fit"
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="icon text-8xl text-gray-500"
                      icon={faImage}
                    />
                  )}
                  <div className="absolute bottom-2 right-2 h-min w-fit rounded-full bg-purewhite p-2 text-black">
                    <FontAwesomeIcon className="block" icon={faPen} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* BUTTONS */}
      <div className="mt-10">
        <FacilitySubmitResetButtons
          onSubmit={() => formik.submitForm()}
          onReset={() => {
            formik.resetForm();
          }}
          disabled={!formik.dirty}
        />
      </div>

      {/* IMAGE UPLOAD DIALOG */}
      <Dialog
        header={intl.formatMessage({
          id: "logo.upload",
          defaultMessage: "Ladda upp logotyp",
        })}
        visible={displayImageModifierDialog}
        onHide={() => setDisplayImageModifierDialog(false)}
      >
        <ImageInput onSave={logoImageUploader} cropper />

        <div
          className={`flex items-center gap-2 font-bold ${
            formik.values.logo
              ? "cursor-pointer"
              : "cursor-not-allowed opacity-20"
          }`}
          onClick={removeFacilityLogoCallback}
        >
          <FormattedMessage id="logo.remove" />
        </div>
      </Dialog>
    </div>
  );
};
