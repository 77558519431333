import { DateTime } from "luxon";

import type {
  CreateOpenBookingRequest,
  OpenBooking,
  OpenBookingApiResponse,
  OpenBookingParticipant,
  OpenBookingRescheduleRequest,
  UpdateOpenBookingRequest,
} from "../models/OpenBookings";
import type { FacilityWithUtc } from "../modules/customer/models/Facility";

import { deletee, get, post, put } from "./apiClient";

const API_VERSION = "v1";

const getEndpointBase = (facilityId: FacilityWithUtc["id"]) =>
  `/${API_VERSION}/facilities/${facilityId}/open-bookings`;

export const createOpenBooking = async (
  facilityId: FacilityWithUtc["id"],
  body: CreateOpenBookingRequest,
): Promise<OpenBooking> => {
  return post<OpenBookingApiResponse>({
    endpoint: getEndpointBase(facilityId),
    body: {
      ...body,
      startTime: body.startTime.toUTC().toISO(),
      endTime: body.endTime.toUTC().toISO(),
      automaticCancellationTime: body.automaticCancellationTime.toUTC().toISO(),
    },
  }).then(formatOpenBooking);
};

export const cancelOpenBooking = async (
  facilityId: FacilityWithUtc["id"],
  id: OpenBooking["id"],
) =>
  post({
    endpoint: `${getEndpointBase(facilityId)}/${id}/cancel`,
    body: {},
    parseDataAs: "none",
  });

export const getOpenBooking = async (
  facilityId: FacilityWithUtc["id"],
  id: OpenBooking["id"],
): Promise<OpenBooking> =>
  get<OpenBookingApiResponse>({
    endpoint: `${getEndpointBase(facilityId)}/${id}`,
  }).then(formatOpenBooking);

export const addParticipantToOpenBooking = async (
  facilityId: FacilityWithUtc["id"],
  openBookingId: OpenBooking["id"],
  participantId: OpenBookingParticipant["id"],
) =>
  post({
    endpoint: `${getEndpointBase(facilityId)}/${openBookingId}/participants`,
    body: {
      userId: participantId,
    },
    parseDataAs: "none",
  });

export const removeParticipantFromOpenBooking = async (
  facilityId: FacilityWithUtc["id"],
  openBookingId: OpenBooking["id"],
  participantId: OpenBookingParticipant["id"],
) =>
  deletee({
    endpoint: `${getEndpointBase(facilityId)}/${openBookingId}/participants/${participantId}`,
  });

export const updateOpenBooking = async (
  facilityId: FacilityWithUtc["id"],
  openBookingId: OpenBooking["id"],
  body: UpdateOpenBookingRequest,
): Promise<OpenBooking> => {
  return put<OpenBookingApiResponse>({
    endpoint: `${getEndpointBase(facilityId)}/${openBookingId}`,
    body: {
      ...body,
      automaticCancellationTime: body.automaticCancellationTime
        ?.toUTC()
        .toISO(),
    },
  }).then(formatOpenBooking);
};

export const rescheduleOpenBooking = async (
  facilityId: FacilityWithUtc["id"],
  openBookingId: OpenBooking["id"],
  body: OpenBookingRescheduleRequest,
): Promise<OpenBooking> => {
  return post<OpenBookingApiResponse>({
    endpoint: `${getEndpointBase(facilityId)}/${openBookingId}/reschedule`,
    body: {
      ...body,
      startTime: body.startTime.toUTC().toISO(),
      endTime: body.endTime.toUTC().toISO(),
    },
  }).then(formatOpenBooking);
};

const formatOpenBooking = (data: OpenBookingApiResponse): OpenBooking => ({
  ...data,
  startTime: DateTime.fromISO(data.startTime),
  endTime: DateTime.fromISO(data.endTime),
  automaticCancellationTime: DateTime.fromISO(data.automaticCancellationTime),
  participants: data.participants.map(participant => ({
    ...participant,
    paymentDate: participant.paymentDate
      ? DateTime.fromISO(participant.paymentDate)
      : null,
  })),
});
