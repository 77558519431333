import { useIsAdminMode } from "../recoil/isAdminModeState";
import { useHasFacilityAdminPermission } from "./permissions";
import { useCurrentUser } from "./swr/useCurrentUser";

export const useIsAdmin = () => {
  const { currentUser } = useCurrentUser();
  const isAdminMode = useIsAdminMode();
  const { hasFacilityAdminPermission } = useHasFacilityAdminPermission();

  return !!currentUser && hasFacilityAdminPermission && isAdminMode;
};
