import { FormattedMessage } from "react-intl";

import type { SportType } from "../modules/customer/models/Facility";

export const SportTypeTranslatedName = ({
  sportType,
}: {
  sportType: NonNullable<SportType["name"]>;
}) => {
  switch (sportType) {
    case "Padel": {
      return <FormattedMessage id="common.padel" />;
    }
    case "Tennis": {
      return <FormattedMessage id="common.tennis" />;
    }
    case "Badminton": {
      return <FormattedMessage id="common.badminton" />;
    }
    case "Squash": {
      return <FormattedMessage id="common.squash" />;
    }
    case "Bordtennis": {
      return <FormattedMessage id="common.table-tennis" />;
    }
    case "Fotbollsgolf": {
      return <FormattedMessage id="common.footgolf" />;
    }
    case "Pickleball": {
      return <FormattedMessage id="common.pickleball" />;
    }
    case "Golf": {
      return <FormattedMessage id="common.golf" />;
    }
    case "Övrigt": {
      return <FormattedMessage id="common.other" />;
    }

    default: {
      return null;
    }
  }
};
