import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { DateOnly } from "../../../models/DateOnly";
import { BookingType } from "../../../modules/checkout/models/Booking";
import { IScheduleResponse } from "../../../modules/checkout/models/Calendar";
import { AdminScheduleSlot } from "../../../modules/checkout/models/schedule";

import { adminGetMobileBookingEditPath } from "../../../helpers/pathHelpers";

import { useSelectedFacility } from "../../../hooks/swr/useSelectedFacility";
import { useDateFormat } from "../../../hooks/useDateFormat";
import { useQuery } from "../../../hooks/useQuery";
import { useSchedules } from "../../../modules/checkout/hooks/calendar/useSchedules";

import { AppLoadingSpinner } from "../../../components/AppLoadingSpinner";
import { ProfileImageWithFallback } from "../../../components/ProfileImageWithFallback";
import CustomerCalendarMenu from "../../../modules/checkout/components/Calendar/customer/CustomerCalendarMenu";
import { bookingTypeBorderLeftColor } from "../../../modules/checkout/components/Calendar/utils";

import { luxonTimeFormat } from "../../../utils/dateFormats";

const filterEvents = (bookings: AdminScheduleSlot[]) => {
  const seenTitles = new Set();
  return bookings.filter(slot => {
    if (slot.bookingType === BookingType.Event) {
      if (seenTitles.has(slot.title)) {
        return false;
      } else {
        seenTitles.add(slot.title);
        return true;
      }
    }
    return true;
  });
};

const getBookingsFromSlots = (
  groupedSlots: Record<string, AdminScheduleSlot[]>,
) => {
  return Object.values(groupedSlots).map((slots: AdminScheduleSlot[]) => {
    const minStartSlot = slots.reduce((minSlot, currentSlot) =>
      currentSlot.startTime.valueOf() < minSlot.startTime.valueOf()
        ? currentSlot
        : minSlot,
    );
    const maxEndSlot = slots.reduce((maxSlot, currentSlot) =>
      currentSlot.endTime.valueOf() > maxSlot.endTime.valueOf()
        ? currentSlot
        : maxSlot,
    );

    return {
      ...slots[0],
      startTime: minStartSlot.startTime,
      endTime: maxEndSlot.endTime,
    };
  });
};

const getAllBookingsFromScheduleSlots = (schedules: IScheduleResponse[]) => {
  const allSlots = schedules.flatMap(schedule =>
    schedule.slots.map(slot => ({
      ...slot,
      courtName: schedule.objectName,
      facilityId: schedule.facilityId,
    })),
  );

  const groupedSlots = allSlots.reduce(
    (groups: Record<string, AdminScheduleSlot[]>, slot: AdminScheduleSlot) => {
      if (slot.bookingId) {
        const key = slot.bookingId;
        if (!groups[key]) {
          groups[key] = [];
        }
        groups[key].push(slot);
      }
      return groups;
    },
    {},
  );

  const slotsBookings = getBookingsFromSlots(groupedSlots);

  /*
    we filter the events to only show one entry per event in the list.
    we show the event name instead of court later
  */
  const filteredBookings = filterEvents(slotsBookings);

  return filteredBookings.sort(
    (a, b) => a.startTime.valueOf() - b.startTime.valueOf(),
  );
};

export const AdminMobileBookingsPage: React.FC = () => {
  const history = useHistory();
  const query = useQuery();
  const { selectedFacility } = useSelectedFacility();
  const [selectedDate, setSelectedDate] = useState<DateOnly>(
    query?.get("date")
      ? DateOnly.fromISODate(query?.get("date") as string)
      : DateOnly.today(),
  );
  const [bookings, setBookings] = useState<AdminScheduleSlot[]>([]);
  const { dfInterval } = useDateFormat(selectedFacility?.id);

  const { schedules, isLoading } = useSchedules(
    selectedDate,
    selectedFacility?.id,
    undefined,
    true,
  );

  const fetchBookings = (date: DateOnly) => {
    setSelectedDate(date);
    const searchParams = new URLSearchParams({ date: date.toISO() });
    history.replace({
      pathname: history.location.pathname,
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    if (!schedules?.length) return;
    setBookings(getAllBookingsFromScheduleSlots(schedules));
  }, [schedules]);

  const onBookingClick = (booking: AdminScheduleSlot) => {
    if (!booking.bookingId) {
      return;
    }

    history.push({
      pathname: adminGetMobileBookingEditPath(booking.bookingId),
    });
  };

  const hasCheckedForBookings = !isLoading && !!schedules?.length;

  return (
    <div className="space-y-2">
      <CustomerCalendarMenu
        selectedDate={selectedDate}
        maxDate={selectedDate.plus({ days: 90 })}
        minDate={selectedDate.minus({ days: 90 })}
        onChangeDate={fetchBookings}
      />

      {!hasCheckedForBookings && (
        <div className="flex justify-center">
          <AppLoadingSpinner hasContainer={false} />
        </div>
      )}

      {!isLoading && !bookings?.length && hasCheckedForBookings && (
        <div className="flex justify-center">
          <FormattedMessage id="admin.booking.no-bookings" />
        </div>
      )}

      {hasCheckedForBookings &&
        bookings.map(booking => (
          <button
            key={booking.bookingId}
            onClick={() => onBookingClick(booking)}
            className={clsx(
              "relative w-full rounded-md border border-l-8 border-gray-50 bg-gradient-to-b from-purewhite to-white p-2 pr-6 text-left",
              booking.bookingType &&
                bookingTypeBorderLeftColor(booking.bookingType),
            )}
          >
            {booking.bookingType && (
              <div>
                <BookingTypeLabel bookingType={booking.bookingType} />
              </div>
            )}
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <ProfileImageWithFallback
                  firstName={booking.bookedBy?.firstName}
                  lastName={booking.bookedBy?.lastName}
                  src={booking.bookedBy?.profileImage}
                  title={booking.bookedBy?.displayName}
                />
                <div>
                  <div className="flex space-x-2 text-black">
                    <p>
                      {dfInterval(
                        booking.startTime,
                        booking.endTime,
                        luxonTimeFormat,
                      )}
                    </p>
                    <p>
                      {booking.bookedBy?.firstName?.charAt(0)}.{" "}
                      {booking.bookedBy?.lastName}
                    </p>
                  </div>
                  <div className="text-gray-700">
                    <p>
                      {booking.bookingType === BookingType.Event
                        ? booking.title
                        : booking.courtName}
                    </p>
                  </div>
                </div>
              </div>
              <FontAwesomeIcon
                icon={faChevronRight}
                className="absolute right-2 top-1/2 -translate-y-1/2 transform text-gray-700"
              />
            </div>

            {booking.title && booking.bookingType !== BookingType.Event && (
              <p className="pt-2 text-sm">{booking.title}</p>
            )}
          </button>
        ))}
    </div>
  );
};

const BookingTypeLabel = ({ bookingType }: { bookingType: BookingType }) => {
  return (
    <div className={clsx("mb-2 text-sm font-semibold")}>
      <FormattedMessage id={bookingTypeMessageIds[bookingType]} />
    </div>
  );
};

const bookingTypeMessageIds: Record<BookingType, string> = {
  [BookingType.NotBookable]: "common.not-bookable-slots",
  [BookingType.Regular]: "common.booking",
  [BookingType.Series]: "common.serie",
  [BookingType.Activity]: "common.activity",
  [BookingType.Recurring]: "common.reoccurring-booking",
  [BookingType.Undefined]: "common.booking",
  [BookingType.Event]: "common.event",
  [BookingType.Subscription]: "",
  [BookingType.Training]: "",
  [BookingType.Open]: "common.open-booking",
};
