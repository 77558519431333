import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import {
  faArrowDownArrowUp,
  faBarsFilter,
  faCheck,
  faSort,
  faSortAlphaAsc,
  faSortAlphaDesc,
  faSortAsc,
  faSortDesc,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "@mui/base/Dropdown";
import { Menu } from "@mui/base/Menu";
import { MenuButton } from "@mui/base/MenuButton";
import { MenuItem } from "@mui/base/MenuItem";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";

import {
  type AdminFacilityUserCoin,
  CoinType,
} from "../../../../../modules/player/models/Coins";

import { adminGetPlayerPath } from "../../../../../helpers/pathHelpers";

import { ProfileImageWithFallback } from "../../../../../components/ProfileImageWithFallback";
import {
  Table,
  TableCurrencyCell,
  TableDateCell,
} from "../../../../../components/Table";

import { luxonDateFormat } from "../../../../../utils/dateFormats";

type Data = AdminFacilityUserCoin;

export const CoinsTable = ({ data }: { data: Data[] }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return <Table table={table} />;
};

const columnHelper = createColumnHelper<Data>();

const columns = [
  columnHelper.accessor(row => `${row.firstName} ${row.lastName}`, {
    id: "displayName",
    sortDescFirst: false,
    sortingFn: "text",
    header: ({ column }) => (
      <button
        type="button"
        onClick={
          (column.getCanSort() && column.getToggleSortingHandler()) || undefined
        }
        className="flex items-center gap-2"
      >
        <FormattedMessage id="common.player" />
        {column.getCanSort() && (
          <FontAwesomeIcon
            className={clsx(column.getIsSorted() && "text-primary")}
            icon={
              column.getIsSorted()
                ? column.getIsSorted() === "asc"
                  ? faSortAlphaAsc
                  : faSortAlphaDesc
                : faArrowDownArrowUp
            }
          />
        )}
      </button>
    ),
    cell: props => {
      const displayName = `${props.row.original.firstName} ${props.row.original.lastName}`;

      return (
        <Link
          to={adminGetPlayerPath(props.row.original.userId)}
          className="float-left flex items-center gap-4 font-inherit text-inherit"
        >
          <ProfileImageWithFallback
            className="size-5 flex-none"
            firstName={props.row.original.firstName}
            lastName={props.row.original.lastName}
            width=""
            title={displayName}
            src={props.row.original.profileImageUrl || undefined}
          />{" "}
          {displayName}
        </Link>
      );
    },
  }),
  columnHelper.accessor("type", {
    enableColumnFilter: true,
    filterFn: "weakEquals",
    header: ({ column }) => (
      <div className="flex items-center gap-1">
        <FormattedMessage id="common.type-of-coins" />

        <Dropdown>
          <MenuButton
            slotProps={{
              root: state => ({
                className: clsx(
                  "rounded p-1 transition-colors hover:bg-gray-50 hover:text-primary",
                  (state.active || state.open || column.getIsFiltered()) &&
                    "bg-gray-50 text-primary",
                ),
              }),
            }}
          >
            <FontAwesomeIcon icon={faBarsFilter} />
          </MenuButton>
          <Menu className="ml-20 mt-1 w-44 rounded bg-white py-4 text-sm font-semibold drop-shadow-md">
            {[CoinType.COIN, CoinType.ALLOWANCE].map(coinType => (
              <MenuItem
                key={coinType}
                className={clsx(
                  "flex cursor-pointer items-center justify-between px-4 py-2 text-pureblack transition-colors hover:bg-blue-50",
                  column.getFilterValue() === coinType && "bg-blue-50",
                )}
                onClick={() =>
                  column.setFilterValue(
                    column.getFilterValue() === coinType ? undefined : coinType,
                  )
                }
              >
                <FormattedMessage
                  id={`coin.type.${CoinType[coinType]?.toLowerCase()}`}
                />
                {column.getFilterValue() === coinType && (
                  <FontAwesomeIcon className="text-primary" icon={faCheck} />
                )}
              </MenuItem>
            ))}
          </Menu>
        </Dropdown>
      </div>
    ),
    cell: props => (
      <FormattedMessage
        id={`coin.type.${CoinType[props.getValue()]?.toLowerCase()}`}
      />
    ),
  }),
  columnHelper.accessor("amount", {
    header: ({ column }) => (
      <div className="flex justify-end">
        <button
          type="button"
          className="flex items-center gap-2"
          onClick={column.getToggleSortingHandler()}
        >
          <FormattedMessage id="common.coins" />
          <FontAwesomeIcon
            className={clsx(column.getIsSorted() && "text-primary")}
            icon={
              column.getIsSorted()
                ? column.getIsSorted() === "asc"
                  ? faSortAsc
                  : faSortDesc
                : faSort
            }
          />
        </button>
      </div>
    ),
    cell: props => (
      <TableCurrencyCell value={props.getValue()} currency="Coins" />
    ),
  }),
  columnHelper.accessor("expiresUtc", {
    header: ({ column }) => (
      <button
        type="button"
        className="flex items-center gap-2"
        onClick={column.getToggleSortingHandler()}
      >
        <FormattedMessage id="common.expiration-date" />
        <FontAwesomeIcon
          className={clsx(column.getIsSorted() && "text-primary")}
          icon={
            column.getIsSorted()
              ? column.getIsSorted() === "asc"
                ? faSortAsc
                : faSortDesc
              : faSort
          }
        />
      </button>
    ),
    cell: props => (
      <TableDateCell
        facilityId={undefined}
        value={props.getValue()}
        dateFormat={luxonDateFormat}
      />
    ),
  }),
];
