import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import {
  NavLink,
  type NavLinkProps,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import {
  type IconDefinition,
  faEye,
  faEyeSlash,
  faImage,
  faLightbulbOn,
  faUserPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import type { FacilityWithUtc } from "../../../modules/customer/models/Facility";

import { useFacility } from "../../../hooks/swr/useFacility";

import { toggleFacilityIsActive } from "../../../modules/customer/services/FacilityService";

import { ProgressSpinner } from "../../../components/ProgressSpinner";
import { QT } from "./components/QT";
import { UserImport } from "./components/UserImport";

export const SuperAdminFacilityPage = () => {
  const { path } = useRouteMatch();
  const { facilityId } = useParams<{ facilityId?: FacilityWithUtc["id"] }>();

  const { facility, isLoading } = useFacility(facilityId);

  if (!facilityId) {
    return;
  }

  if (isLoading) {
    return <ProgressSpinner />;
  }

  if (!facility) {
    return <div>No facility found.</div>;
  }

  return (
    <div className="relative overflow-hidden rounded bg-purewhite px-4 py-5 md:px-10 md:py-12">
      <Header facilityId={facilityId} />

      <div className="mt-10 md:mt-20">
        <Navigation />
      </div>

      <div className="mt-8 md:mt-16">
        <Switch>
          <Route exact path={`${path}/qt`}>
            <QT facilityId={facilityId} />
          </Route>

          <Route>
            <UserImport facilityId={facilityId} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

const Header = ({ facilityId }: { facilityId: FacilityWithUtc["id"] }) => {
  const { facility, mutate } = useFacility(facilityId);

  const toggleIsActive = useCallback(() => {
    if (!facility) {
      return;
    }

    toggleFacilityIsActive(facilityId);
    mutate(
      {
        ...facility,
        isActive: !facility.isActive,
      },
      false,
    );
  }, [facility, facilityId, mutate]);

  if (!facilityId || !facility) {
    return;
  }

  return (
    <>
      <div className="absolute left-0 right-0 top-0 hidden h-[135px] bg-gradient-to-r from-primary to-primary-dark sm:block" />
      <div className="relative z-10 flex flex-col items-start justify-between gap-4 lg:flex-row lg:items-end">
        <div className="flex max-w-full flex-auto flex-col gap-4 sm:flex-row sm:items-end">
          <div className="flex aspect-square w-full flex-none items-center justify-center rounded-lg border border-gray-50 bg-purewhite p-1 sm:w-24 md:w-[171px] md:p-4">
            {facility.logo ? (
              <img
                src={facility.logo}
                alt={facility.name}
                className="max-h-full max-w-full"
              />
            ) : (
              <FontAwesomeIcon
                className="icon text-8xl text-gray-500"
                icon={faImage}
              />
            )}
          </div>
          <div className="min-w-0">
            <p className="text-2xl font-bold sm:truncate md:text-4xl">
              {facility.name}
            </p>
            {facility.address?.street && (
              <p className="text-gray-700">
                {facility.address.street}
                {", "}
                {facility.address.postalCode} {facility.address.city}
              </p>
            )}
          </div>
        </div>
        <div className="flex gap-1 rounded border border-transparent text-gray-700 outline outline-1 outline-gray-50">
          <button
            disabled={facility.isActive}
            className={clsx(
              "flex items-center gap-2 rounded p-2 transition-colors",
              facility.isActive
                ? "bg-primary/10 font-bold text-primary"
                : "hover:text-pureblack",
            )}
            onClick={toggleIsActive}
          >
            <FontAwesomeIcon icon={faEye} />
            <FormattedMessage id="common.published" />
          </button>
          <button
            disabled={!facility.isActive}
            className={clsx(
              "flex items-center gap-2 rounded p-2 transition-colors",
              !facility.isActive
                ? "bg-primary/10 font-bold text-primary"
                : "hover:text-pureblack",
            )}
            onClick={toggleIsActive}
          >
            <FormattedMessage id="common.hidden" />
            <FontAwesomeIcon icon={faEyeSlash} />
          </button>
        </div>
      </div>
    </>
  );
};

const Navigation = () => {
  const { url } = useRouteMatch();

  return (
    <nav className="grid grid-cols-2 gap-4 md:grid-cols-3 xl:grid-cols-5">
      <NavItem to={url} exact icon={faUserPlus}>
        <FormattedMessage id="common.import-users" />
      </NavItem>
      <NavItem to={`${url}/qt`} icon={faLightbulbOn}>
        <FormattedMessage id="superadmin.facility.qt.title" />
      </NavItem>
    </nav>
  );
};

const NavItem = ({
  icon,
  ...props
}: NavLinkProps & { icon: IconDefinition }) => {
  return (
    <NavLink
      {...props}
      replace
      className={isActive =>
        clsx(
          "group flex flex-col items-center justify-center gap-3 rounded border px-3 pb-4 pt-6 text-inherit hover:border-primary",
          isActive
            ? "!border-primary !bg-primary/10 font-bold"
            : "border-gray-50 font-inherit",
        )
      }
    >
      <div
        className={clsx(
          "flex size-12 items-center justify-center rounded-full bg-blue-50 group-aria-[current]:bg-primary/20",
        )}
      >
        <FontAwesomeIcon icon={icon} />
      </div>

      <span className="max-w-full truncate">{props.children}</span>
    </NavLink>
  );
};
