import { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import {
  faCrown,
  faInfoCircle,
  faTrash,
  faTrashSlash,
  faUserPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { DateTime } from "luxon";
import { InputSwitch } from "primereact/inputswitch";
import type { KeyedMutator } from "swr";

import type { User } from "../../../../../../player/models/User";
import { BookingType, type IBooking } from "../../../../../models/Booking";
import { PaymentMethodName } from "../../../../../models/Payment";

import { adminGetPlayerPath } from "../../../../../../../helpers/pathHelpers";

import { useToaster } from "../../../../../../../hooks/common/useToaster";
import { useCurrencyFormat } from "../../../../../../../hooks/useCurrencyFormat";
import { useDateFormat } from "../../../../../../../hooks/useDateFormat";

import {
  addParticipantToOpenBooking,
  removeParticipantFromOpenBooking,
} from "../../../../../../../services/facilityOpenBookingsService";
import { adminAddPlayerToBooking } from "../../../../../../player/services/GroupService";
import {
  markBookingAsPaid,
  unBookPlayerAdmin,
} from "../../../../../services/Booking";

import { ConfirmationDialog } from "../../../../../../../components/ConfirmationDialog";
import { ProfileImageWithFallback } from "../../../../../../../components/ProfileImageWithFallback";
import { UserSearch } from "../../../../../../../components/UserSearch";

interface Props {
  booking: IBooking;
  isBookingEditable?: boolean;
  showPaymentData?: boolean;
  mutateBooking: KeyedMutator<IBooking>;
  onSubmitted: (refreshView?: boolean, isEditDialogOpen?: boolean) => void;
}

export const Players = ({
  booking,
  isBookingEditable,
  showPaymentData,
  mutateBooking,
  onSubmitted,
}: Props) => {
  const { formatMessage } = useIntl();

  const toaster = useToaster();
  const { df } = useDateFormat(booking.facilityId);
  const { cf } = useCurrencyFormat(booking?.price.currencyCode);
  const { cf: cfCoins } = useCurrencyFormat("Coins");

  const [addPlayerFieldVisible, setAddPlayerFieldVisible] = useState(false);
  const [addUserToBooking, setAddUserToBooking] = useState<{
    user: User | undefined;
    loading: boolean;
  }>({
    user: undefined,
    loading: false,
  });
  const [removePlayerUser, setRemovePlayerUser] = useState<{
    user: User | undefined;
    loading: boolean;
  }>({
    user: undefined,
    loading: false,
  });
  const [markPlayerAsPaidData, setMarkPlayerAsPaidData] = useState<{
    user: User | undefined;
    loading: boolean;
    sendEmail: boolean;
  }>({
    user: undefined,
    loading: false,
    sendEmail: true,
  });
  const userSearchRef = useRef<{ focus: () => void }>(null);

  const participants = useMemo(
    () =>
      booking.participants.toSorted(participant =>
        participant.id === booking.organizerId ? -1 : 0,
      ),
    [booking.organizerId, booking.participants],
  );

  useEffect(() => {
    setAddPlayerFieldVisible(false);
  }, [booking.id]);

  const removePlayer = async (userId: string) => {
    try {
      if (booking.type === BookingType.Open && booking.externalServiceId) {
        await removeParticipantFromOpenBooking(
          booking.facilityId,
          booking.externalServiceId,
          userId,
        );
      } else {
        await unBookPlayerAdmin(booking.id, {
          alwaysRefund: true,
          userIds: [userId],
          refundAll: false,
        });
      }

      booking.participants = booking.participants.filter(x => x.id !== userId);

      mutateBooking(booking, false);
    } catch (e) {
      toaster.toastError.unknown();
    }
  };

  useEffect(() => {
    if (addPlayerFieldVisible && userSearchRef.current) {
      userSearchRef.current.focus();
    }
  }, [addPlayerFieldVisible]);

  return (
    <div className="mt-6">
      <p className="font-medium text-gray-700">
        <FormattedMessage id="common.players" />
      </p>
      <div className="mt-1.5 grid gap-x-12 gap-y-4 sm:grid-cols-2">
        {participants.map(participant => {
          const payment = booking.payments.find(
            x => x.userId === participant.id,
          );

          return (
            <div className="flex gap-3" key={participant.id}>
              <Link
                to={adminGetPlayerPath(participant.id)}
                className="relative text-inherit"
              >
                <ProfileImageWithFallback
                  src={participant.profileImage}
                  firstName={participant.firstName}
                  lastName={participant.lastName}
                  className="size-10 flex-none"
                />
                {booking.organizerId === participant.id && (
                  <div className="absolute -right-1 bottom-0 rounded-full border border-gray-50 bg-white p-1">
                    <FontAwesomeIcon icon={faCrown} size="xs" />
                  </div>
                )}
              </Link>
              <div className="overflow-hidden">
                <Link
                  to={adminGetPlayerPath(participant.id)}
                  className="truncate font-semibold"
                >
                  {participant.displayName}
                </Link>
                <button
                  type="button"
                  onClick={() =>
                    !participant.isPayed &&
                    !booking.isPaid &&
                    setMarkPlayerAsPaidData(v => ({
                      ...v,
                      user: participant,
                      loading: false,
                    }))
                  }
                  className={clsx(
                    "admin-calendar-details mt-auto block truncate font-medium",
                    participant.isPayed
                      ? "cursor-default text-green-700"
                      : !booking.isPaid
                        ? "group text-red-600 hover:text-green-700"
                        : "cursor-default text-red-600",
                  )}
                  data-pr-tooltip={
                    participant.paymentMethod === PaymentMethodName.External
                      ? `${formatMessage({ id: "admin.marked-as-paid-by" })}
                        ${payment?.adminUser?.displayName}
                        ${payment?.lastModified && df(payment?.lastModified, DateTime.DATETIME_MED)}`
                      : payment?.lastModified
                        ? df(payment?.lastModified, DateTime.DATETIME_MED)
                        : undefined
                  }
                >
                  {showPaymentData ? (
                    participant.isPayed && participant.totalPayedIncTax ? (
                      <>
                        {payment && (
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="mr-1 inline-block"
                          />
                        )}
                        {participant.paymentMethod === PaymentMethodName.Coins
                          ? cfCoins(participant.totalPayedIncTax)
                          : cf(participant.totalPayedIncTax)}{" "}
                        <FormattedMessage
                          id={`admin.calendar.payment-method.${participant.paymentMethod}`}
                        />
                      </>
                    ) : (
                      <>
                        <span className="group-hover:hidden">
                          <FormattedMessage id="bookings.not-payed" />
                        </span>
                        <span className="hidden group-hover:inline">
                          <FormattedMessage id="common.mark-as-paid" />
                          ...
                        </span>
                      </>
                    )
                  ) : null}
                </button>
              </div>

              {isBookingEditable && (
                <>
                  <button
                    type="button"
                    onClick={() =>
                      booking.organizerId !== participant.id &&
                      setRemovePlayerUser({
                        user: participant,
                        loading: false,
                      })
                    }
                    className={clsx(
                      "admin-calendar-details ml-1 mt-1 flex-none self-start",
                      booking.organizerId === participant.id &&
                        "cursor-default text-gray-500",
                    )}
                    data-pr-tooltip={
                      booking.organizerId === participant.id
                        ? "Det går inte att ta bort boknings-ansvarig från bokningen."
                        : `${formatMessage({ id: "admin.remove-player" })}...`
                    }
                    data-pr-position="top"
                  >
                    <FontAwesomeIcon
                      icon={
                        booking.organizerId === participant.id
                          ? faTrashSlash
                          : faTrash
                      }
                    />
                  </button>
                </>
              )}
            </div>
          );
        })}

        {isBookingEditable &&
          booking.court.bookableEntityType?.defaultNumberOfPlayers &&
          booking.participants.length <
            booking.court.bookableEntityType.defaultNumberOfPlayers &&
          Array.from({
            length:
              booking.court.bookableEntityType.defaultNumberOfPlayers -
              booking.participants.length,
          }).map((_, i) => (
            <button
              type="button"
              onClick={() => setAddPlayerFieldVisible(v => !v)}
              className="flex items-center gap-3 justify-self-start font-semibold max-sm:hidden max-sm:last:flex"
              key={i}
            >
              <div className="flex size-10 flex-none items-center justify-center rounded-full bg-gray-300">
                <FontAwesomeIcon icon={faUserPlus} size="sm" />
              </div>
              <FormattedMessage id="admin.player-overview.add-player" />
            </button>
          ))}
      </div>

      {addPlayerFieldVisible && (
        <div className="mt-4">
          <UserSearch
            ref={userSearchRef}
            translationId="common.find-player-to-add"
            facilityId={booking.facility.id}
            multiSelect={false}
            displaySelection={false}
            filterUsers={booking.participants}
            onChange={selectedUsers =>
              setAddUserToBooking({
                user: selectedUsers[0],
                loading: false,
              })
            }
          />
        </div>
      )}

      {addUserToBooking.user && (
        <ConfirmationDialog
          visible
          title={addUserToBooking.user.displayName}
          text={
            <FormattedMessage id="admin.edit-booking-form.add-player.confirmation.text" />
          }
          loading={addUserToBooking.loading}
          onHide={() =>
            setAddUserToBooking({
              user: undefined,
              loading: false,
            })
          }
          onCancel={() =>
            setAddUserToBooking({
              user: undefined,
              loading: false,
            })
          }
          onSubmit={async () => {
            if (!addUserToBooking.user) {
              return;
            }

            setAddUserToBooking(v => ({
              ...v,
              loading: true,
            }));

            try {
              if (
                booking.type === BookingType.Open &&
                booking.externalServiceId
              ) {
                await addParticipantToOpenBooking(
                  booking.facilityId,
                  booking.externalServiceId,
                  addUserToBooking.user.id,
                );
              } else {
                await adminAddPlayerToBooking(
                  booking.facilityId,
                  booking.id,
                  booking.groupId,
                  addUserToBooking.user.id,
                );
              }

              mutateBooking();
              setAddPlayerFieldVisible(false);
            } catch (e) {
              toaster.toastError.unknown();
            } finally {
              setAddUserToBooking({
                user: undefined,
                loading: false,
              });
            }
          }}
        />
      )}

      {removePlayerUser.user && (
        <ConfirmationDialog
          visible
          title={formatMessage({ id: "admin.remove-player" })}
          text={
            <FormattedMessage
              id="admin.calendar.edit-booking.players.remove-player.confirmation.text"
              values={{
                Bold: chunk => <b>{chunk}</b>,
                name: removePlayerUser.user.displayName,
              }}
            />
          }
          loading={removePlayerUser.loading}
          confirmButtonType="danger"
          onHide={() =>
            setRemovePlayerUser({ user: undefined, loading: false })
          }
          onCancel={() =>
            setRemovePlayerUser({ user: undefined, loading: false })
          }
          onSubmit={async () => {
            if (!removePlayerUser.user) {
              return;
            }

            setRemovePlayerUser(v => ({ ...v, loading: true }));
            await removePlayer(removePlayerUser.user.id);
            setRemovePlayerUser({
              user: undefined,
              loading: false,
            });
          }}
        />
      )}

      {markPlayerAsPaidData.user && (
        <ConfirmationDialog
          visible
          title={formatMessage({ id: "admin.markAsPaid.confirm.title" })}
          text={
            <FormattedMessage
              id="admin.markAsPaid.confirm.description"
              values={{
                Bold: chunk => <b>{chunk}</b>,
                name: markPlayerAsPaidData.user.displayName,
              }}
            />
          }
          loading={markPlayerAsPaidData.loading}
          onHide={() =>
            setMarkPlayerAsPaidData({
              user: undefined,
              loading: false,
              sendEmail: true,
            })
          }
          onCancel={() =>
            setMarkPlayerAsPaidData({
              user: undefined,
              loading: false,
              sendEmail: true,
            })
          }
          onSubmit={async () => {
            if (!markPlayerAsPaidData.user) {
              return;
            }

            setMarkPlayerAsPaidData(v => ({ ...v, loading: true }));
            try {
              await markBookingAsPaid(
                booking.id,
                markPlayerAsPaidData.user.id,
                markPlayerAsPaidData.sendEmail,
              );
              booking.participants = booking.participants.map(x =>
                x.id === markPlayerAsPaidData.user?.id
                  ? { ...x, isPayed: true }
                  : x,
              );
              mutateBooking(booking);
              onSubmitted(true, true);
            } catch {
              toaster.toastError.unknown();
            } finally {
              setMarkPlayerAsPaidData({
                user: undefined,
                loading: false,
                sendEmail: true,
              });
            }
          }}
        >
          <div className="mt-5 flex items-center justify-center gap-5">
            <label htmlFor="sendEmail">
              <FormattedMessage id="admin.booking.send-confirmation-email" />
            </label>
            <InputSwitch
              inputId="sendEmail"
              checked={markPlayerAsPaidData.sendEmail}
              onChange={e =>
                setMarkPlayerAsPaidData(v => ({
                  ...v,
                  sendEmail: e.value,
                }))
              }
            />
          </div>
        </ConfirmationDialog>
      )}
    </div>
  );
};
