import React from "react";
import { Route, RouteComponentProps, RouteProps } from "react-router-dom";

import { useRecoilState } from "recoil";

import { useIsAdmin } from "./hooks/useIsAdmin";
import { useIsAuthenticated } from "./hooks/useIsAuthenticated";

import { msalInstance } from "./index";
import { UserOverview } from "./pages/UserOverview";
import { useAppI18nConfig } from "./recoil/i18nConfigState";
import { isAdminModeState } from "./recoil/isAdminModeState";

interface IProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>>;
  admin?: boolean;
}

const PrivateRoute: React.FC<IProps> = ({
  component: Component,
  admin = false,
  ...rest
}) => {
  const [isAdminMode, setIsAdminMode] = useRecoilState(isAdminModeState);
  const isAuthenticated = useIsAuthenticated();
  const isAdmin = useIsAdmin();
  const {
    i18nConfig: { languageCode },
  } = useAppI18nConfig();

  if (!isAuthenticated) {
    msalInstance.loginRedirect({
      scopes: [],
      extraQueryParameters: { lang: languageCode },
    });
  }

  const accessAllowed = admin ? isAdmin : true;

  if (!accessAllowed && isAdminMode) {
    setIsAdminMode(false);
  }

  return (
    <Route
      {...rest}
      render={props =>
        accessAllowed ? <Component {...props} /> : <UserOverview />
      }
    />
  );
};

export default PrivateRoute;
