import { DateTime } from "luxon";

import { DateOnly } from "../../../models/DateOnly";
import type { OpenBooking } from "../../../models/OpenBookings";
import { TimeOnly } from "../../../models/TimeOnly";
import { RecurringBookingType } from "../../../models/common";
import { BookableEntity } from "../../../modules/customer/models/Facility";
import { FacilityWithUtc } from "../../customer/models/Facility";
import { GeneralActivities } from "../../game/models/GeneralActivities";
import { Series, SeriesDivision } from "../../game/models/Series";
import { User } from "../../player/models/User";
import { Games } from "../models/Games";
import { Payment } from "../models/Payment";

import { Price } from "./Pricing";

export interface ICreateBookingFormValues {
  facilityId: string;
  courtId: string;
  startTime: DateTime;
  endTime: DateTime;
  type: BookingType;
  organizerId: string;
  isOrganizerParticipant: boolean;
  participants: string[];
  paymentType: PaymentType;
  playersFilterType: PlayersFilterType;
  gender?: string;
  ageGroup?: string;
  skillLevelLower?: number;
  skillLevelUpper?: number;
  price?: Price;
  isAdmin?: boolean;
  recurringBookingId?: boolean;
  daysOfWeek?: number[];
  interval?: number;
  recurringGroupType?: RecurringBookingType;
  recurringDayOfMonthType?: string;
  comment?: string;
  isShownInCalendar?: boolean;
  turnOnLight?: boolean;
  fixedPriceIncTax?: number;
}

export interface ICreateRecurringBookingFormValues
  extends Omit<ICreateBookingFormValues, "startTime" | "endTime"> {
  fromDate: DateOnly;
  toDate: DateOnly;
  startTime: TimeOnly;
  endTime: TimeOnly;
}

export interface IRescheduleBookingFormValues {
  facilityId: string;
  courtId: string;
  startTime: DateTime;
  endTime: DateTime;
}

export interface IBooking {
  bookedBy: BookingParticipant;
  bookingNumber: string;
  comment?: string;
  court: BookableEntity;
  createdDate?: DateTime;
  endTime: DateTime;
  externalReferenceId?: SeriesDivision["id"];
  externalServiceId?:
    | Series["id"]
    | GeneralActivities["id"]
    | OpenBooking["id"];
  facility: Pick<
    FacilityWithUtc,
    | "address"
    | "bookableEntityTypes"
    | "email"
    | "id"
    | "name"
    | "phone"
    | "localization"
  >;
  facilityId: string;
  fee: Price;
  games: Pick<Games, "id" | "groupId">[];
  groupId: string;
  id: string;
  individualDefaultPrice?: Price;
  individualFee?: Price;
  individualPrice?: Price;
  isAdmin: boolean;
  isPaid: boolean | null;
  isShownInCalendar: boolean;
  isSplitPayment: boolean;
  organizer: BookingParticipant;
  organizerId: string;
  participants: BookingParticipant[];
  payments: (Payment & {
    adminUser: { displayName: string } | null;
    lastModified: DateTime | null;
  })[];
  price: Price;
  reschedulesCount: number;
  startTime: DateTime;
  status: BookingStatus;
  type: BookingType;
  turnOnLight: boolean;
}

export enum BookingStatus {
  New = 0,
  Cancelled = 1,
  Confirmed = 2,
  Draft = 3,
}

export enum BookingType {
  NotBookable = 0,
  Regular = 1,
  Subscription = 2,
  Series = 3,
  Activity = 4,
  Training = 5,
  Recurring = 6,
  Undefined = 7,
  Event = 8,
  Open = 9,
}

export enum PaymentType {
  Solid = 0,
  Split = 1,
}

export enum PlayersFilterType {
  Specific = 0,
  Friends = 1,
  Public = 2,
}

export interface IUpdateBookingCommentFormValues {
  id: string;
  comment: string;
  isShownInCalendar: boolean;
}

export interface IAdminCancelBookingRequest {
  // Refound independent off 6hour have to be Admin
  alwaysRefund: boolean;
  userIds: string[];

  //Refound include BookingFee have to be SuperUser
  refundAll: boolean;
}

export type BookingParticipant = Pick<
  User,
  | "displayName"
  | "emailAddress"
  | "firstName"
  | "id"
  | "isPayed"
  | "lastName"
  | "locale"
  | "paymentMethod"
  | "phoneNumber"
  | "profileImage"
  | "skillLevel"
  | "totalPayedIncTax"
>;

export interface InitialAdminCalendarBookingData {
  facilityId: FacilityWithUtc["id"];
  courtId: BookableEntity["id"];
  startTime: DateTime;
  endTime: DateTime;
}
