import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useIsAuthenticated } from "../hooks/useIsAuthenticated";
import { useQuery } from "../hooks/useQuery";

import { msalInstance } from "../index";

export const SignUpUnverifiedPage: React.FC = () => {
  const history = useHistory();
  const searchParams = useQuery();
  const firstName = searchParams.get("firstName") || "";
  const lastName = searchParams.get("lastName") || "";
  const country = searchParams.get("country") || "";
  const email = searchParams.get("email") || "";
  const consent = searchParams.get("consent") || "";
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      msalInstance.loginRedirect({
        authority: process.env.REACT_APP_B2C_AUTH_SIGNUP_UNVERIFIED_AUTH,
        scopes: [],
        extraQueryParameters: {
          firstName,
          lastName,
          country,
          email,
          consent,
        },
      });
    } else {
      history.push({ pathname: "/" });
    }
  }, [isAuthenticated]);

  return <></>;
};
