import useSWRImmutable from "swr/immutable";

import { getSportTypes } from "../../modules/customer/services/FacilityService";

export const useSportTypes = () => {
  const { data, ...rest } = useSWRImmutable("sportTypes", () =>
    getSportTypes(0, 1000).then(res => res.data),
  );

  return {
    ...rest,
    sportTypes: data,
  };
};
