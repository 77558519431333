import useSWR, { SWRConfiguration } from "swr";

import { Order } from "../../../models/Order";

import { getOrder } from "../../../services/myOrdersService";

export const useOrder = (
  orderId?: Order["id"] | null,
  options?: SWRConfiguration,
) => {
  const { data, ...rest } = useSWR(
    orderId ? ["order", orderId] : null,
    ([, orderId]) => getOrder(orderId),
    options,
  );

  return {
    ...rest,
    order: data,
  };
};
