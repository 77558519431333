import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useIsAuthenticated } from "../hooks/useIsAuthenticated";

import { msalInstance } from "../index";

export const CreateAccountPage = () => {
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      msalInstance.loginRedirect({
        authority: process.env.REACT_APP_B2C_AUTH_SIGNUP_AUTH,
        scopes: [],
      });
    } else {
      history.push({ pathname: "/" });
    }
  }, [history, isAuthenticated]);

  return null;
};
