import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import {
  faCalendar,
  faCalendarTimes,
  faCalendarXmark,
  faCreditCard,
  faEllipsisVertical,
  faPeople,
  faUserPlus,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import useSWR from "swr";

import type { OrderItemRequest } from "../../../../models/OrderItem";
import { BookingType } from "../../../checkout/models/Booking";
import { Games } from "../../../checkout/models/Games";

import { useIsMobile } from "../../../../hooks/common/useIsMobile";
import { useToaster } from "../../../../hooks/common/useToaster";
import { useCurrentUser } from "../../../../hooks/swr/useCurrentUser";
import { useFacility } from "../../../../hooks/swr/useFacility";
import { useDateFormat } from "../../../../hooks/useDateFormat";

import { leaveOpenBooking } from "../../../../services/myOpenBookingsService";
import { createOrder } from "../../../../services/myOrdersService";
import { getOpenBookings } from "../../../../services/publicOpenBookingsService";
import { cancelBooking } from "../../../checkout/services/Booking";

import { ConfirmationDialog } from "../../../../components/ConfirmationDialog";
import { IsPaidBadge } from "../../../../components/IsPaidBadge";
import { LogoWithFallback } from "../../../../components/LogoWithFallback";
import { TeamCaptainBadge } from "../../../../components/TeamCaptainBadge";
import BookingActionsDialog from "../../../checkout/components/BookingActionsDialog";
import { Player } from "../../../checkout/components/Games/teams/Player";

import { luxonTimeFormat } from "../../../../utils/dateFormats";

interface Props {
  game: Games;
  showUpcoming: boolean;
  handleInviteFriend: (isShow: boolean, externalServiceId: string) => void;
  handleTeams: (Id: string) => void;
  handleReschedule: (facilityId: string, bookingId: string) => void;
  refreshData?: () => void;
}

export const GameContent = ({
  game,
  showUpcoming,
  handleInviteFriend,
  handleTeams,
  handleReschedule,
  refreshData,
}: Props) => {
  const { toastSuccess, toastError } = useToaster();
  const intl = useIntl();
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const [showActionsDialog, setShowActionsDialog] = useState(false);
  const { df, dfInterval } = useDateFormat(game?.facility?.id);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const isMobile = useIsMobile();
  const [isLoading, setIsLoading] = useState(false);
  const { facility } = useFacility(game?.facility?.id, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);

  const isSerie = BookingType.Series === game?.bookingType;
  const isOrganizer = game?.organizerId === currentUser?.id;

  const { data: openBooking } = useSWR(
    game.bookingType === BookingType.Open
      ? ["open-bookings", { bookingId: game.externalServiceId }]
      : undefined,
    ([, params]) => getOpenBookings(params).then(data => data.result.at(0)),
  );

  const handleGoToPayment = async () => {
    setIsCreatingOrder(true);
    let orderItem: OrderItemRequest;

    if (game.bookingType === BookingType.Open && openBooking) {
      orderItem = {
        $type: "open-booking",
        openBookingId: openBooking.id,
      };
    } else {
      switch (game?.bookingType) {
        case BookingType.Recurring:
        case BookingType.Regular: {
          orderItem = {
            $type: "booking",
            bookingId: game.externalServiceId,
          };
          break;
        }
        case BookingType.Series: {
          orderItem = {
            $type: "series",
            seriesId: game.externalServiceId,
          };
          break;
        }
        case BookingType.Event: {
          orderItem = {
            $type: "event",
            eventId: game.externalServiceId,
          };
          break;
        }
        default:
          toastError.generalFailure();
          setIsCreatingOrder(false);
          return;
      }
    }

    try {
      const order = await createOrder(game?.facility?.id, orderItem);

      history.push(`/checkout/${order.id}`);
    } catch (ex: any) {
      console.log(ex?.message);
      toastError.generalFailure();
      setIsCreatingOrder(false);
    }
  };

  const gameIsPaid = () => {
    if (game?.isPayed) {
      return true;
    }

    return (
      game?.participants?.some(
        x => x.id == currentUser?.id && x.isPayed != null && x.isPayed,
      ) ?? false
    );
  };

  const handleCancellationConfimed = async () => {
    setIsLoading(true);

    try {
      if (game.bookingType === BookingType.Open) {
        if (!openBooking) {
          throw new Error("Open booking not found");
        }

        await leaveOpenBooking(openBooking.id);
      } else {
        await cancelBooking(game.externalServiceId);
      }

      toastSuccess.bookingCancelled();

      setTimeout(() => {
        refreshData?.();
      }, 1000);
    } catch (e) {
      toastError.cancelBookingFailed();
    }

    setShowCancelDialog(false);
    setIsLoading(false);
  };

  return (
    <>
      <div className="flex w-full items-start justify-between gap-4 overflow-hidden rounded border border-gray-200 p-4 transition-shadow duration-200 ease-in-out">
        {isMobile ? (
          <>
            <div className="flex w-full flex-auto flex-row gap-2">
              <div className="flex items-center">
                <div className="flex w-16 flex-col items-center rounded text-center">
                  <div className="text-xs font-bold capitalize leading-none text-pureblack">
                    {df(game?.startTime, { weekday: "long" })}
                  </div>
                  <div className="text-2xl font-bold leading-tight text-pureblack">
                    {df(game?.startTime, { day: "numeric" })}
                  </div>
                  <div className="text-xs font-bold leading-tight text-pureblack">
                    {df(game?.startTime, { month: "long" })}
                  </div>

                  {isSerie && (
                    <div className="text-xs leading-tight text-gray-600">
                      <FormattedMessage id="common.serie" />
                    </div>
                  )}
                </div>
              </div>
              <div className="mx-3 h-12 w-[2.5px] self-center bg-gray-150" />
              <div className="flex w-full gap-4">
                <div>
                  <div className="flex h-full min-w-[5rem] grow flex-col justify-between text-gray-600">
                    <div className="text-md text-pureblack">
                      {game?.facility?.name}
                    </div>
                    <div className="text-sm text-gray-400">
                      {game?.court?.name}
                      {game?.court?.courtType?.name}
                    </div>
                    <div className="flex w-full gap-1 text-sm font-bold text-pureblack">
                      {df(game?.startTime, luxonTimeFormat)} -{" "}
                      {df(game?.endTime, luxonTimeFormat)}
                    </div>

                    {isSerie && game?.games?.[0]?.finished && (
                      <div>
                        {`${
                          game?.games[0]?.teams.find(t =>
                            t.users.some(uid => uid == currentUser?.id),
                          )?.winningTeam ? (
                            <FormattedMessage id="common.win" />
                          ) : (
                            <FormattedMessage id="common.loss" />
                          )
                        } ${
                          game?.games[0]?.teams.find(t =>
                            t.users.some(uid => uid == currentUser?.id),
                          )?.calculatedPoints
                        }p`}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <button
                className="flex flex-col items-end justify-between"
                onClick={() => setShowActionsDialog(true)}
              >
                <FontAwesomeIcon
                  icon={faEllipsisVertical}
                  className="text-gray-400"
                />

                {!isSerie && !gameIsPaid() && (
                  <span className="text-xs text-red-500">
                    <FormattedMessage id="bookings.not-payed" />
                  </span>
                )}
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="flex w-full gap-5">
              <LogoWithFallback src={game?.facility?.logo} />
              <div className="flex h-20 flex-col items-center justify-between text-center font-bold text-gray-700">
                <div className="h-4 capitalize">
                  {df(game?.startTime, { weekday: "long" })}
                </div>
                <div className="h-12 text-[2.8rem] leading-[3.4rem] text-pureblack">
                  {df(game?.startTime, { day: "numeric" })}
                </div>
                <div className="h-4">
                  {df(game?.startTime, { month: "long" })}
                </div>
              </div>
              <div className="min-w-0 grow text-gray-700">
                <div className="font-bold">
                  {dfInterval(game?.startTime, game?.endTime, luxonTimeFormat)}
                </div>
                <div className="truncate">{game?.court?.name}</div>
                <div className="flex min-h-8 gap-2">
                  {isSerie &&
                    game?.participants
                      ?.filter(p =>
                        game?.games?.[0]?.teams[0]?.users.some(
                          uid => uid == p.id,
                        ),
                      )
                      .map(user => (
                        <div key={user.id}>
                          <div className="relative">
                            <Player user={user} />
                            {game?.organizerId === user?.id && (
                              <TeamCaptainBadge className="absolute bottom-[-0.3rem] right-[-0.3rem] text-xs" />
                            )}
                            {user?.isPayed && (
                              <IsPaidBadge className="absolute bottom-[-0.3rem] left-[-0.3rem] text-xs" />
                            )}
                          </div>
                        </div>
                      ))}

                  {isSerie && (
                    <p
                      style={{
                        alignSelf: "center",
                        fontFamily: "var(--bold)",
                      }}
                    >
                      vs
                    </p>
                  )}
                  {isSerie &&
                    game?.participants
                      ?.filter(p =>
                        game?.games?.[0]?.teams[1]?.users.some(
                          uid => uid == p.id,
                        ),
                      )
                      .map(user => (
                        <div key={user.id}>
                          <div className="relative">
                            <Player user={user} />
                            {game?.organizerId === user?.id && (
                              <TeamCaptainBadge className="absolute bottom-[-0.3rem] right-[-0.3rem] text-xs" />
                            )}
                            {user?.isPayed && (
                              <IsPaidBadge className="absolute bottom-[-0.3rem] left-[-0.3rem] text-xs" />
                            )}
                          </div>
                        </div>
                      ))}

                  {!isSerie &&
                    game?.participants?.map(user => (
                      <div key={user.id}>
                        <div className="relative">
                          <Player user={user} />
                          {game?.organizerId === user?.id && (
                            <TeamCaptainBadge className="absolute bottom-[-0.3rem] right-[-0.3rem] text-xs" />
                          )}
                          {user?.isPayed && (
                            <IsPaidBadge className="absolute bottom-[-0.3rem] left-[-0.3rem] text-xs" />
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="flex flex-col items-end gap-2 text-nowrap">
                {isSerie &&
                  (game.seriesName ?? (
                    <FormattedMessage
                      id="common.serie"
                      defaultMessage="Serie"
                    />
                  ))}

                {!showUpcoming && (
                  <button
                    className="flex items-center gap-2 font-bold text-primary transition-colors hover:text-primary-light"
                    onClick={() => handleTeams(game.id)}
                  >
                    {game?.games &&
                    game.games.length > 0 &&
                    game.games[0]?.finished ? (
                      <>
                        <FontAwesomeIcon
                          icon={faPeople}
                          className="text-black"
                        />
                        <FormattedMessage id="games.register-result.locked" />
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon
                          icon={faPeople}
                          className="text-black"
                        />
                        <FormattedMessage id="games.register-result" />
                      </>
                    )}
                  </button>
                )}

                {isSerie &&
                  game.games &&
                  game.games.length > 0 &&
                  game.games[0]?.finished && (
                    <p>
                      <span>
                        {`${game.games[0].teams[0].calculatedSetWins} - ${game.games[0].teams[1].calculatedSetWins}`}
                      </span>
                      <span className="ml-1">
                        {`(${game.games[0].teams[0].calculatedPoints}p - ${game.games[0].teams[1].calculatedPoints}p)`}
                      </span>
                    </p>
                  )}

                {!isSerie &&
                  game.participants &&
                  game.participants.length < 4 &&
                  game.bookingType !== BookingType.Open && (
                    <button
                      className="flex items-center gap-2 font-bold text-primary transition-colors hover:text-primary-light"
                      onClick={() =>
                        handleInviteFriend(true, game.externalServiceId)
                      }
                    >
                      <FontAwesomeIcon
                        icon={faUserPlus}
                        className="text-black"
                      />
                      <FormattedMessage id="games.invite-players" />
                    </button>
                  )}

                {!isSerie &&
                  showUpcoming &&
                  (game?.bookingType === BookingType.Open
                    ? !openBooking ||
                      openBooking.automaticCancellationTime > DateTime.now()
                    : game?.startTime > DateTime.now()) && (
                    <button
                      className="flex items-center gap-2 font-bold text-primary transition-colors hover:text-primary-light"
                      onClick={() => setShowCancelDialog(true)}
                    >
                      <FontAwesomeIcon
                        icon={faCalendarTimes}
                        className="text-black"
                      />
                      {isOrganizer && game.bookingType !== BookingType.Open ? (
                        <FormattedMessage id="games.cancel" />
                      ) : (
                        <FormattedMessage id="games.leave-game" />
                      )}
                    </button>
                  )}

                {!gameIsPaid() && (
                  <button
                    type="button"
                    className="flex items-center gap-2 font-bold text-primary transition-colors hover:text-primary-light"
                    disabled={isCreatingOrder}
                    onClick={() => handleGoToPayment()}
                  >
                    <FontAwesomeIcon
                      icon={faCreditCard}
                      className="text-black"
                    />
                    <FormattedMessage id="games.pay" />
                  </button>
                )}

                {isSerie &&
                  DateTime.now() <
                    game?.startTime.minus({
                      hours:
                        facility?.facilityBookingRules
                          .numberOfHoursBeforeSeriesGameReschedule,
                    }) && (
                    <button
                      onClick={() => {
                        handleReschedule(
                          game?.facility?.id,
                          game.externalServiceId,
                        );
                      }}
                      className={`reschedule-button-${game?.externalServiceId} flex items-center gap-2 font-bold text-primary transition-colors hover:text-primary-light`}
                    >
                      <FontAwesomeIcon
                        icon={faCalendar}
                        className="text-black"
                      />
                      <FormattedMessage id="games.reschedule" />
                    </button>
                  )}
              </div>
            </div>
          </>
        )}
      </div>

      {showActionsDialog && (
        <BookingActionsDialog
          game={game}
          options={
            showUpcoming && !isSerie
              ? [
                  {
                    icon: faCreditCard,
                    translationId: "games.pay",
                    defaultMessage: "Betala bokning",
                    disabled: gameIsPaid() || isCreatingOrder,
                    hidden: gameIsPaid(),
                    onClick: () => {
                      setShowActionsDialog(false);
                      handleGoToPayment();
                    },
                  },
                  {
                    icon: faUserPlus,
                    disabled: game.participants && game.participants.length > 4,
                    translationId: "games.invite-players",
                    defaultMessage: "Bjud in spelare",
                    hidden: game.bookingType === BookingType.Open,
                    onClick: () => {
                      setShowActionsDialog(false);
                      handleInviteFriend(true, game.externalServiceId);
                    },
                  },
                  {
                    icon: faCalendarXmark,

                    disabled:
                      game?.bookingType === BookingType.Open
                        ? openBooking &&
                          openBooking.automaticCancellationTime < DateTime.now()
                        : game?.startTime < DateTime.now(),
                    translationId: isOrganizer
                      ? "games.cancel"
                      : "games.leave-game",
                    defaultMessage: "Avboka",
                    onClick: () => {
                      setShowActionsDialog(false);
                      setShowCancelDialog(true);
                    },
                  },
                ]
              : [
                  {
                    icon: faCreditCard,
                    translationId: "games.pay",
                    defaultMessage: "Betala bokning",
                    disabled: gameIsPaid() || isSerie || isCreatingOrder,
                    hidden: gameIsPaid() || isSerie,
                    onClick: () => {
                      setShowActionsDialog(false);
                      handleGoToPayment();
                    },
                  },
                  {
                    icon: faPeople,
                    translationId:
                      game.games &&
                      game.games.length > 0 &&
                      game.games[0]?.finished
                        ? "games.register-result.locked"
                        : "games.register-result",
                    defaultMessage:
                      game.games &&
                      game.games.length > 0 &&
                      game.games[0]?.finished
                        ? "Se resultat"
                        : "Registrera resultat",

                    hidden: DateTime.now() < game.startTime,
                    onClick: () => {
                      setShowActionsDialog(false);
                      handleTeams(game.id);
                    },
                  },
                  {
                    icon: faCalendar,
                    translationId: "games.reschedule",
                    defaultMessage: "Omboka",

                    hidden:
                      !isSerie ||
                      DateTime.now() >
                        game?.startTime.minus({
                          hours:
                            facility?.facilityBookingRules
                              .numberOfHoursBeforeSeriesGameReschedule,
                        }),
                    onClick: () => {
                      handleReschedule(
                        game?.facility?.id,
                        game.externalServiceId,
                      );
                    },
                  },
                  {
                    icon: faUserPlus,
                    disabled: game.participants && game.participants.length > 4,
                    hidden: isSerie,
                    translationId: "games.invite-players",
                    defaultMessage: "Bjud in spelare",
                    onClick: () => {
                      setShowActionsDialog(false);
                      handleInviteFriend(true, game.externalServiceId);
                    },
                  },
                ]
          }
          visible
          onHide={() => setShowActionsDialog(false)}
        />
      )}

      {showCancelDialog && (
        <ConfirmationDialog
          icon={faCalendarXmark}
          visible={showCancelDialog}
          title={intl.formatMessage({
            id: isOrganizer ? "bookings.remove" : "bookings.remove-player",
          })}
          text={intl.formatMessage(
            {
              id: isOrganizer
                ? "bookings.remove.are-you-sure"
                : "bookings.remove-player.are-you-sure",
            },
            {
              br: <br />,
              numberOfHoursBeforeBookingCancellation:
                facility?.facilityBookingRules
                  .numberOfHoursBeforeBookingCancellation,
            },
          )}
          denyText={intl.formatMessage({
            id: isOrganizer
              ? "bookings.remove.reject"
              : "bookings.remove-player.reject",
          })}
          confirmText={intl.formatMessage({
            id: isOrganizer
              ? "common.confirm.remove"
              : "bookings.remove-player.confirm",
          })}
          loading={isLoading}
          onHide={() => setShowCancelDialog(false)}
          onCancel={() => setShowCancelDialog(false)}
          onSubmit={() => handleCancellationConfimed()}
        />
      )}
    </>
  );
};
