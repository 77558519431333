import { FormattedMessage } from "react-intl";

import clsx from "clsx";

import { FacilityWithUtc } from "../modules/customer/models/Facility";

import { useFacility } from "../hooks/swr/useFacility";

import { useAppI18nConfig } from "../recoil/i18nConfigState";

interface Props {
  skipRescheduleInfo?: boolean;
  facilityId: FacilityWithUtc["id"];
  className?: string;
}

export const PaymentTerms = ({
  skipRescheduleInfo,
  facilityId,
  className,
}: Props) => {
  const {
    i18nConfig: { languageCode },
  } = useAppI18nConfig();
  const { facility } = useFacility(
    !skipRescheduleInfo ? facilityId : undefined,
  );

  const textId = skipRescheduleInfo
    ? "terms-and-condition.payment.short"
    : "terms-and-condition.payment";

  return (
    <>
      <div className={clsx("leading-none text-gray-700", className)}>
        <FormattedMessage
          id={textId}
          values={{
            clickable: (
              <a
                href={
                  process.env.REACT_APP_WEBSITE_URL +
                  (languageCode === "sv"
                    ? "/sv/terms-and-conditions"
                    : "/terms-and-conditions")
                }
                target="_blank"
                rel="noreferrer"
              >
                <FormattedMessage id="terms-and-conditions.link.text" />
              </a>
            ),
            numberOfHoursBeforeBookingCancellation:
              facility?.facilityBookingRules
                .numberOfHoursBeforeBookingCancellation,
          }}
        />
      </div>
    </>
  );
};
