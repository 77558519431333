import useSWR, { type SWRConfiguration } from "swr";

import { getSavedPaymentMethods } from "../../../services/mySettingsService";

import { useCurrentUserId } from "../../../recoil/currentUserIdState";

export const useSavedPaymentMethods = (options?: SWRConfiguration) => {
  const currentUserId = useCurrentUserId();

  const { data, ...rest } = useSWR(
    ["savedPaymentMethods", currentUserId],
    () => getSavedPaymentMethods(),
    options,
  );

  return {
    ...rest,
    savedPaymentMethods: data,
  };
};
