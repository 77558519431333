import { toast } from "react-toastify";

import { InteractionRequiredAuthError } from "@azure/msal-browser";

import { msalInstance } from "..";
import { loginRequest } from "../authConfig";
import { createUrlSearchParams } from "../utils/createUrlSearchParams";

if (!process.env.REACT_APP_BASE_API_URL) {
  throw new Error("REACT_APP_BASE_API_URL is not defined");
}

interface BaseRequestOptions {
  endpoint: string;
  fetchOptions: RequestInit;
  urlSearchParams?: Record<
    string,
    string | number | boolean | undefined | null
  >;
}

interface RequestOptions extends BaseRequestOptions {
  parseDataAs?: "json" | "blob" | "none";
}

type ApiClientRequestOptions = Pick<
  RequestOptions,
  "endpoint" | "urlSearchParams"
>;

interface ApiClientRequestOptionsWithBody extends ApiClientRequestOptions {
  body: Record<string, any>;
}

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

class ParseDataAsError extends Error {}

/**
 * Makes an HTTP request to the specified endpoint.
 * @param {RequestOptions} options - The options for the request.
 * @returns {Promise<T>} - A promise that resolves with the response data.
 * @throws {Response} - The response object if the request fails.
 * @throws {ParseDataAsError} - If the parseDataAs option is not valid.
 * @throws {Error} - If an unknown error occurs.
 */
export function request<T>(
  requestOptions: BaseRequestOptions & { parseDataAs?: "json" },
): Promise<T>;
export function request(
  requestOptions: BaseRequestOptions & { parseDataAs: "none" },
): Promise<never>;
export function request(
  requestOptions: BaseRequestOptions & { parseDataAs: "blob" },
): Promise<Blob>;
export async function request<T>({
  endpoint,
  fetchOptions,
  parseDataAs = "json",
  ...options
}: RequestOptions): Promise<T | Blob | undefined> {
  const headers = new Headers(fetchOptions.headers);

  if (options.urlSearchParams) {
    endpoint += "?" + createUrlSearchParams(options.urlSearchParams).toString();
  }

  headers.set(
    "Ocp-Apim-Subscription-Key",
    String(process.env.REACT_APP_SUBSCRIPTION_KEY),
  );

  try {
    const response = await msalInstance.acquireTokenSilent(loginRequest);

    headers.set("Authorization", `Bearer ${response.accessToken}`);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      await msalInstance.acquireTokenRedirect(loginRequest);
    } else {
      await msalInstance.loginRedirect();
    }

    throw error;
  }

  const response = await fetch(BASE_URL + endpoint, {
    ...fetchOptions,
    headers,
  });

  if (!response.ok) {
    throw response;
  }

  if (response.status === 204) {
    return;
  }

  try {
    switch (parseDataAs) {
      case "json": {
        if (!response.headers.get("Content-Type")?.includes("json")) {
          throw new ParseDataAsError(
            "content-type header does not include 'json'",
          );
        }

        return response.json();
      }

      case "blob": {
        return response.blob();
      }

      case "none": {
        return;
      }

      default: {
        // Used to catch unhandled options
        const exhaustiveCheck: never = parseDataAs;
        throw new ParseDataAsError(exhaustiveCheck);
      }
    }
  } catch (error) {
    toast.error("An unknown error occurred");

    throw error;
  }
}

export function post<T>(
  options: ApiClientRequestOptionsWithBody & { parseDataAs?: "json" },
): Promise<T>;
export function post(
  options: ApiClientRequestOptionsWithBody & { parseDataAs: "none" },
): Promise<never>;
export function post(
  options: ApiClientRequestOptionsWithBody & { parseDataAs: "blob" },
): Promise<Blob>;
export function post<T>({ body, ...options }: ApiClientRequestOptionsWithBody) {
  return request<T>({
    ...options,
    fetchOptions: {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    },
  });
}

export function get<T>(
  options: ApiClientRequestOptions & { parseDataAs?: "json" },
): Promise<T>;
export function get(
  options: ApiClientRequestOptions & { parseDataAs: "none" },
): Promise<never>;
export function get(
  options: ApiClientRequestOptions & { parseDataAs: "blob" },
): Promise<Blob>;
export function get<T>(options: ApiClientRequestOptions) {
  return request<T>({
    ...options,
    fetchOptions: { method: "GET" },
  });
}

export function put<T>(
  options: ApiClientRequestOptionsWithBody & { parseDataAs?: "json" },
): Promise<T>;
export function put(
  options: ApiClientRequestOptionsWithBody & { parseDataAs: "none" },
): Promise<never>;
export function put(
  options: ApiClientRequestOptionsWithBody & { parseDataAs: "blob" },
): Promise<Blob>;
export function put<T>({ body, ...options }: ApiClientRequestOptionsWithBody) {
  return request<T>({
    ...options,
    fetchOptions: {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    },
  });
}

export function deletee(options: ApiClientRequestOptions) {
  return request({
    ...options,
    parseDataAs: "none",
    fetchOptions: { method: "DELETE" },
  });
}
