import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { DateOnly } from "../../../models/DateOnly";
import type { IBooking } from "../../../modules/checkout/models/Booking";

import {
  adminGetMobileBookingReschedulePath,
  adminGetMobileBookingsPath,
} from "../../../helpers/pathHelpers";

import { useBooking } from "../../../hooks/swr/useBooking";

import {
  AdminCalendarProvider,
  useAdminCalendarContextState,
} from "../../../modules/checkout/components/Calendar/admin/AdminCalendarContext";
import { AdminCalendarDetails } from "../../../modules/checkout/components/Calendar/admin/details/AdminCalendarDetails";

export const AdminMobileEditBookingPage: React.FC = () => {
  const history = useHistory();

  const { bookingId } = useParams<{ bookingId: IBooking["id"] }>();
  const { booking, isLoading } = useBooking(bookingId);

  useEffect(() => {
    if (!booking && !isLoading) {
      history.replace(adminGetMobileBookingsPath());
    }
  }, [booking, history, isLoading]);

  if (!booking) {
    return null;
  }

  return (
    <AdminCalendarProvider
      facilityId={booking.facilityId}
      selectedDate={DateOnly.today()}
      selectedBookingTypes={[]}
      isExpanded={false}
    >
      <Component bookingId={booking.id} />
    </AdminCalendarProvider>
  );
};

const Component = ({ bookingId }: { bookingId: IBooking["id"] }) => {
  const history = useHistory();
  const { booking, mutate } = useBooking(bookingId);
  const { bookingBeingRescheduled } = useAdminCalendarContextState();

  useEffect(() => {
    if (!bookingBeingRescheduled || !booking) {
      return;
    }

    console.log("navigate to reschedule page");
    history.push(
      adminGetMobileBookingReschedulePath(booking.facilityId, booking.id),
    );
  }, [booking, bookingBeingRescheduled, history]);

  const handleEditDialogClose = (
    refreshView = true,
    isEditDialogOpen = false,
  ) => {
    console.log("handleEditDialogClose", {
      refreshView,
      isEditDialogOpen,
      bookingBeingRescheduled,
    });

    if (refreshView) {
      mutate();
      return;
    }
  };

  if (!booking) {
    return null;
  }

  return (
    <div className="rounded-lg bg-purewhite">
      <AdminCalendarDetails
        bookingId={booking.id}
        onSubmitted={handleEditDialogClose}
      />
    </div>
  );
};
