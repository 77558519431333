import type { DateTime } from "luxon";

import type { IBooking } from "../modules/checkout/models/Booking";
import type {
  BookableEntity,
  FacilityWithUtc,
} from "../modules/customer/models/Facility";
import type { User } from "../modules/player/models/User";

import type { PaymentMethod } from "./payment";

export interface OpenBooking
  extends Omit<
    OpenBookingApiResponse,
    "startTime" | "endTime" | "automaticCancellationTime" | "participants"
  > {
  startTime: DateTime;
  endTime: DateTime;
  automaticCancellationTime: DateTime;
  participants: OpenBookingParticipant[];
}

export interface CreateOpenBookingRequest {
  courtId: BookableEntity["id"];
  classCategory: OpenBookingClassCategoryEnum;
  minSkillLevel: number;
  maxSkillLevel: number;
  automaticCancellationTime: DateTime;
  startTime: DateTime;
  endTime: DateTime;
  fixedPriceIncTax?: number;
  comment?: string;
  isCommentVisibleInCalendar: boolean;
  participants: OpenBookingParticipant["id"][];
}

export interface UpdateOpenBookingRequest {
  classCategory?: OpenBookingClassCategoryEnum;
  minSkillLevel?: number;
  maxSkillLevel?: number;
  automaticCancellationTime?: DateTime;
}

export interface OpenBookingApiResponse {
  id: string;
  bookingId: IBooking["id"];
  facilityId: FacilityWithUtc["id"];
  courtId: BookableEntity["id"];
  courtName: BookableEntity["name"] | null;
  requiredPlayerCount: number;
  classCategory: OpenBookingClassCategoryEnum;
  minSkillLevel: number;
  maxSkillLevel: number;
  startTime: string;
  endTime: string;
  automaticCancellationTime: string;
  hasRequiredPlayerCount: boolean;
  participants: OpenBookingParticipantApiResponse[];
}

export enum OpenBookingClassCategoryEnum {
  Men = "Men",
  Women = "Women",
  Mixed = "Mixed",
}

interface OpenBookingParticipantBaseApiResponse {
  id: User["id"];
  displayName: string | null;
  profileImageUrl: string | null;
  skillLevel: number;
  hasPaid: boolean;
}

interface OpenBookingParticipantPaidApiResponse
  extends OpenBookingParticipantBaseApiResponse {
  hasPaid: true;
  paymentDate: string;
  paymentInstrument: PaymentMethod["instrument"];
}

interface OpenBookingParticipantUnPaidApiResponse
  extends OpenBookingParticipantBaseApiResponse {
  hasPaid: false;
  paymentDate: null;
  paymentInstrument: null;
}

export type OpenBookingParticipantApiResponse =
  | OpenBookingParticipantPaidApiResponse
  | OpenBookingParticipantUnPaidApiResponse;

type OpenBookingParticipantUnPaid = OpenBookingParticipantBaseApiResponse;

interface OpenBookingParticipantPaid
  extends Omit<OpenBookingParticipantPaidApiResponse, "paymentDate"> {
  paymentDate: DateTime;
}

export type OpenBookingParticipant =
  | OpenBookingParticipantPaid
  | OpenBookingParticipantUnPaid;

export interface OpenBookingRescheduleRequest {
  courtId: BookableEntity["id"];
  startTime: DateTime;
  endTime: DateTime;
  sendEmailToParticipants: boolean;
}
