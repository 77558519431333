import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { DateTime } from "luxon";
import { Skeleton } from "primereact/skeleton";
import useSWR from "swr";

import { IBooking } from "../../../../models/Booking";

import { adminGetPlayerPath } from "../../../../../../helpers/pathHelpers";

import { useGeneralActivity } from "../../../../../../hooks/swr/useGeneralActivity";
import { useCurrencyFormat } from "../../../../../../hooks/useCurrencyFormat";
import { useDateFormat } from "../../../../../../hooks/useDateFormat";

import { getAllBookingsOnExternalId } from "../../../../services/Booking";

import { ProgressSpinner } from "../../../../../../components/ProgressSpinner";
import { DataGrid } from "./components/DataGrid";

interface Props {
  booking: IBooking;
}

export const AdminCalendarEventDetails = ({ booking }: Props) => {
  const { df } = useDateFormat(booking.facilityId);
  const { cf } = useCurrencyFormat(booking?.price.currencyCode);

  const { activity: event, isLoading: isEventLoading } = useGeneralActivity(
    booking.externalServiceId,
    "",
  );

  const { data: allBookings, isLoading: isAllBookingsLoading } = useSWR(
    booking.externalServiceId
      ? ["allBookingOnExternalId", booking.externalServiceId]
      : undefined,
    ([, id]) => getAllBookingsOnExternalId(id).then(res => res.data),
  );

  if (isEventLoading) {
    return <ProgressSpinner />;
  }

  if (!event) {
    return null;
  }

  return (
    <DataGrid
      data={[
        {
          heading: <FormattedMessage id="common.date-and-time" />,
          text: (
            <>
              {df(booking.startTime, DateTime.DATETIME_MED)} -{" "}
              {df(booking.endTime, DateTime.TIME_SIMPLE)}
            </>
          ),
        },
        {
          heading: <FormattedMessage id="common.booked-by" />,
          text: (
            <Link to={adminGetPlayerPath(booking.bookedBy.id)}>
              {booking.bookedBy.displayName}
            </Link>
          ),
        },
        {
          heading: <FormattedMessage id="common.courts" />,
          text: isAllBookingsLoading ? (
            <Skeleton />
          ) : (
            allBookings?.map(x => (
              <span className="block" key={x.id}>
                {x.court.name}
              </span>
            ))
          ),
        },
        {
          heading: <FormattedMessage id="common.price-per-person" />,
          text: cf(event.price.valueInclTax),
        },
      ]}
    />
  );
};
