import { useCallback, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { faDownload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { Calendar } from "primereact/calendar";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import useSWR from "swr";

import { breakpoints } from "../../../../../appConstants/common";

import { DateOnly } from "../../../../../models/DateOnly";
import { SettlementReport } from "../../../../../modules/customer/models/SettlementReport";

import { useFacilityLocalization } from "../../../../../hooks/store/useFacilityLocalization";
import { useCurrencyFormat } from "../../../../../hooks/useCurrencyFormat";
import { useDateFormatWithSelectedFacility } from "../../../../../hooks/useDateFormat";

import {
  downloadSettlementReportPDF,
  getSettlementReports,
} from "../../../../../modules/customer/services/SettlementReportsService";

import { BackEndPaginatedDataTable } from "../../../../../components/DataTable/BackEndPaginatedDataTable";
import { CalendarInput } from "../../../../../components/inputs/CalendarInput";

import { pageNumberState } from "../../../../../recoil/Admin/settings/settlementReportsState";
import { useSelectedFacilityId } from "../../../../../recoil/selectedFacilityIdState";
import { luxonDateFormat } from "../../../../../utils/dateFormats";

const ROWS_PER_PAGE = 10;

const CalendarInputWrapper = styled.div`
  @media (min-width: ${breakpoints.MOBILE}) {
    width: 14rem;
  }

  @media (max-width: ${breakpoints.MOBILE}) {
    flex: 1;
  }
`;

export const FacilitySettlementReports = () => {
  const intl = useIntl();
  const [pageNumber, setPageNumber] = useRecoilState<number>(pageNumberState);
  const [fromDate, setFromDate] = useState<DateTime>();
  const [toDate, setToDate] = useState<DateTime>();
  const [isEndDateSelected, setIsEndDateSelected] = useState<boolean>(false);
  const selectedFacilityId = useSelectedFacilityId();
  const { dfInterval } = useDateFormatWithSelectedFacility();
  const [resetDates, setResetDates] = useState<boolean>(false);
  const localization = useFacilityLocalization();
  const { cf } = useCurrencyFormat(localization?.currencyCode, {
    minimumFractionDigits: 2,
  });

  const getReportName = useCallback(
    ({ fromDate, toDate }: SettlementReport) => {
      const period = dfInterval(fromDate, toDate, luxonDateFormat);

      return intl.formatMessage(
        {
          id: "facility-settings.settlement-reports.pdf-name",
          defaultMessage: "Avräkningsrapport {period}",
        },
        { period },
      );
    },
    [],
  );

  const columns = [
    {
      field: "facilityId",
      body: getReportName,
      id: "common.name",
      defaultMessage: "Namn",
    },
    {
      field: "totalAmount",
      body: ({ totalAmount }: SettlementReport) =>
        totalAmount ? cf(totalAmount) : "",
      id: "common.total-amount",
      defaultMessage: "Totalt belopp",
    },
    {
      field: "pdf",
      body: (row: SettlementReport) =>
        row.fortnoxDocumentNumber && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              downloadSettlementReportPDF(
                row.fortnoxDocumentNumber,
                getReportName(row),
              )
            }
          >
            <FontAwesomeIcon icon={faDownload} />
          </div>
        ),
      id: "common.download-pdf",
      defaultMessage: "Ladda ned PDF",
    },
  ];

  const { data: settlementReports, isLoading } = useSWR(
    selectedFacilityId
      ? [
          "settlementReports",
          selectedFacilityId,
          fromDate?.toISODate(),
          toDate?.toISO(),
          pageNumber,
        ]
      : undefined,
    ([, selectedFacilityId, fromDate, toDate, pageNumber]) =>
      getSettlementReports(
        selectedFacilityId,
        fromDate ? DateOnly.fromISODate(fromDate) : null,
        toDate ? DateOnly.fromISODate(toDate) : null,
        pageNumber,
        ROWS_PER_PAGE,
      ),
    { keepPreviousData: true },
  );

  const ref = useRef<Calendar>();

  return (
    <>
      <div className="mb-4 flex flex-col justify-between gap-x-8 gap-y-4 sm:flex-row sm:items-center">
        <h3>
          <FormattedMessage id="facility-settings.settlement-reports.title" />
        </h3>

        <CalendarInputWrapper>
          <CalendarInput
            todayButtonClassName="invisible"
            placeholder={intl.formatMessage({
              id: "common.filter.date",
              defaultMessage: "Filtrera på datum",
            })}
            ref={ref}
            selectionMode="range"
            onChange={e => {
              if (!e.value) {
                setFromDate(undefined);
                setToDate(undefined);
                setResetDates(true);
                return;
              }

              const [start, end] = e.value as unknown as DateTime[];
              if (start && end) {
                setFromDate(start);
                setToDate(end);
                setIsEndDateSelected(true);
                setPageNumber(1);
                ref.current?.hide();
              }
              if (start && !end) {
                setIsEndDateSelected(false);
              }
            }}
            maxDate={DateTime.local()}
            isEndDateSelected={isEndDateSelected}
            resetDates={resetDates}
          />
        </CalendarInputWrapper>
      </div>

      <BackEndPaginatedDataTable
        loading={isLoading}
        rowsPerPage={ROWS_PER_PAGE}
        pagination={settlementReports?.totalNUmberOfReports > ROWS_PER_PAGE}
        useColumnBorder
        data={settlementReports?.items.toSorted((a, b) =>
          b.fromDate < a.fromDate ? -1 : b.fromDate > a.fromDate ? 1 : 0,
        )}
        columns={columns}
        emptyMessage={
          <FormattedMessage
            id="facility-settings.settlement-reports.no-data"
            defaultMessage="Det finns inga avräkningsrapporter"
          />
        }
        totalNumberOfRecords={settlementReports?.totalNUmberOfReports}
      />
    </>
  );
};
