import { atom, useRecoilValue } from "recoil";

import { localStoragePersistenceEffect } from "./effects/localStoragePersistenceEffect";

export const adminCalendarVisibleCourtFilterState = atom<
  "all" | "open" | "closed"
>({
  key: "adminCalendarVisibleCourtFilter_v2",
  default: "open",
  effects: [
    localStoragePersistenceEffect("adminCalendarVisibleCourtFilter_v2"),
  ],
});

export const useAdminCalendarVisibleCourtFilterState = () =>
  useRecoilValue(adminCalendarVisibleCourtFilterState);
